import React from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";

const users = [
  {
    title: "Gladyce",
    avatar: "/images/content/avatar.jpg",
    url: "",
  },
  {
    title: "Elbert",
    avatar: "/images/content/avatar-1.jpg",
    url: "",
  },
  {
    title: "Joyce",
    avatar: "/images/content/avatar-2.jpg",
    url: "",
  },
  {
    title: "Gladyce",
    avatar: "/images/content/avatar.jpg",
    url: "",
  },
  {
    title: "Elbert",
    avatar: "/images/content/avatar-1.jpg",
    url: "",
  },
];

const Users = ({ className,record }) => {
  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.list}>
        {record?.length > 0 && record?.map((x, index) => (
          <div className={styles.item} key={index} to={x?.url}>
            <div className={styles.avatar}>
              <img src={x?.profileUrl ?? "/images/content/avatar-1.jpg"} alt="Avatar" />
            </div>
            <div className={styles.title}>{x?.userName}</div>
          </div>
        ))}
           {record?.length>5 &&
        <Link className={styles.all} to="/referrals/referrals-list">
          <div className={styles.icon}>
            <Icon name="arrow-right" size="24" />
          </div>
       <div className={styles.text}>View all</div>
        </Link>}
      </div>
    </div>
  );
};

export default Users;
