import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmationModal = (props) => {
  const { onClose, onSave, disabled } = props;
  const { t } = useTranslation('translation');
  
  return (
        <div className="d-flex justify-content-center align-items-center flex-column text-center my-5">
        <div>
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#2A85FF"/>
            <path d="M15.7148 36.4364V38.215C15.7148 39.6358 16.2793 40.9984 17.2839 42.0031C18.2886 43.0077 19.6512 43.5721 21.072 43.5721H38.9291C40.3499 43.5721 41.7125 43.0077 42.7172 42.0031C43.7219 40.9984 44.2863 39.6358 44.2863 38.215V36.4293M30.0006 15.8936V35.5364M30.0006 35.5364L36.2506 29.2864M30.0006 35.5364L23.7506 29.2864" stroke="white" stroke-width="4.54055" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
        <div className="h3 fw-bold p-4">
          {t('brandkit_download_confirmation')}
        </div>
        <div className="d-flex justify-content-around w-100 px-3">
        <button
          className="btn btn-outline-secondary fw-semibold w-100 p-2 me-2"
          onClick={onClose}
          disabled={disabled}
        >
          {t('no_cancel')}
        </button>
        <button
          style={{backgroundColor: '#0084FF'}}
          className="btn btn-primary text-white fw-semibold w-100 p-2 ms-2"
          onClick={onSave}
          disabled={disabled}
        >
          {t('yes_download')}
        </button>
      </div>
      </div>
   
  );
};
export default ConfirmationModal;
