import React, { useState } from "react";
import styles from "./Table.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import cn from "classnames";
import { WithdrawlHistory } from "../../../mocks/withdrawlHistory";
import { useTranslation } from "react-i18next";

const TransactionTable = ({ transLoading, data, transferToConnectAccount }) => {
  const { t } = useTranslation("translation");

  const intervals = [t("last_7days"), t("this_month"), t("all_time")];

  const [sorting, setSorting] = useState(intervals[0]);

  const getPlanAmount = (sub, planId) => {
    const plan = sub?.plans?.find(
      (i) => i?._id?.toString() == planId?.toString()
    );
    return Math.floor(Number(plan?.price) / 5) ?? 0;
  };

  return (
    <div className={styles.wrapper}>
      <Card
        className={cn(styles.card)}
        title={t("pending_withdrawals")}
        classTitle="title-purple"
        classCardHead={styles.classCardHead}
        //  head={
        //    <Dropdown
        //      className={styles.dropdown}
        //      classDropdownHead={styles.dropdownHead}
        //      value={sorting}
        //      setValue={setSorting}
        //      options={intervals}
        //      small
        //    />
        //  }
      />
      <table className={styles.Table}>
        <thead className={styles.Thead}>
          <tr className={styles.Row}>
            <th scope="col" className={styles.head}>
              {t("subscription_date")}
            </th>
            <th scope="col" className={styles.head}>
              {t("user")}
            </th>
            <th scope="col" className={styles.head}>
              {t("campaign")}
            </th>
            <th scope="col" className={styles.head}>
              {t("total_amount")}
            </th>
            <th scope="col" className={styles.head}>
              {t("actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.length > 0 &&
            data?.map((item, index) => (
              <tr className={styles.Row} key={index}>
                <td className={styles.Td} scope="row" data-label={t("date")}>
                  {new Date(item?.createdAt).toDateString()}
                </td>
                <td
                  className={styles.Td}
                  scope="row"
                  data-label={t("transaction")}
                >
                  {item?.user?.userName ?? item?.user?.email}
                </td>
                <td
                  style={{ whiteSpace: "normal" }}
                  className={styles.Td}
                  scope="row"
                  data-label={t("transaction")}
                >
                  <p style={{ wordWrap: "break-word" }}>
                    {item?.campaignId?.title}
                  </p>
                </td>
                <td
                  className={styles.Td + " " + styles.TdAmount}
                  scope="row"
                  data-label={t("total_amount")}
                >
                  {Number(item?.plan?.price) / 5 ?? 0}
                </td>
                <td
                  className={styles.Td}
                  style={transLoading == true ? { opacity: 0.2 } : {}}
                  scope="row"
                  data-label={t("actions")}
                >
                  <button
                    disabled={transLoading}
                    onClick={() => {
                      transferToConnectAccount({
                        amount: Number(item?.plan?.price) / 5 ?? 0,
                        againstUser: item?.user?._id,
                        transId: item?._id,
                      });
                    }}
                    className={styles.withdrawl}
                  >
                    {t("withdraw")}
                  </button>
                </td>
                {/* <td className={styles.Td} scope="row" data-label="Actions"><span className={styles.TdActions}>{"View Receipt"}</span></td>getPlanAmount(item?.subscription, item?.plan) */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
