import { createAsyncThunk } from "@reduxjs/toolkit";
import campaignService from "../../services/compaign.service";


export const generateComapign = createAsyncThunk(
  "compaignStore/store",
  async (data) => {
    try {
      const response = await campaignService.store(data);
      return response.data;
    } catch (error) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);
export const getAllComapign = createAsyncThunk(
  "compaignStore/getAllComapign",
  async (params) => {
    try {
      const response = await campaignService.index(params);
      console.log(response.data.data, "response");
      return response.data.data;
    } catch (error) {
      if (error.response) {
        // If the error has a 'response' property
        const { response } = error;
        throw response.data.message || response.statusText;
      } else {
        // If there is no 'response' property in the error object
        throw "An error occurred while fetching data.";
      }
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "compaignStore/deleteCompaign",
  async (id) => {
    try {
      const response = await campaignService.destroy(id);
      return response.data;
    } catch (error) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
)



export const updateCompaignById = createAsyncThunk(
  "compaignStore/updateCompaignById",
  async (data) => {
    try {
      const response = await campaignService.store(data);
      return response.data; // Assuming your API returns the user data
    } catch (error) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

// export const destroyAudio = createAsyncThunk(
//   "audioStore/destroyAudio",
//   async (id: any) => {
//     try {
//       const response = await audioService.destroy(id);
//       return response.data;
//     } catch (error: any) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// )

