import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCampaign,
  generateComapign,
  getAllComapign,
  updateCompaignById,
} from "../actions/compaign.actions";
import { getCampaignState, getDashboardOverview, getIncomeData, getTotalData } from "../actions/superuser.action";

export const initialState = {
  totalData: {
    totalCompaigns: 0,
    totalRefferals: 0,
    totalCommission: 0,
    totalRevenue: 0,
    loading: false,
    error: null,
    filter:'This week'
  },
  dashboardOverview: {
    ddOptions: ["This week", "This month", "This year"],
    refferals: 0,
    commision: 0,
    campaigns: 0,
    commisionGraphData: [],
    loading: false,
    error: null
  },
  campaignStateGraph: {
    ddOptions: ["This week", "This month"],
    graphData: [],
    loading: false,
    error: null
  },
  incomeData: {
    overView: {
      totalEarning: {
        staticsPercentage: 0,
        value: 0
      },
      availableBalance: {
        staticsPercentage: 0,
        value: 0
      },
      totalWithDrawn: {
        staticsPercentage: 0,
        value: 0
      },
      recentWithdrawn: {
        staticsPercentage: 0,
        value: 0
      },
      loading: false,
      error:null,
    },
    graphData: {
      ddOptions: ["This week", "This month"],
      graphData: [],
      loading: false
    }
  },
  globalStates:{
    filter:'This week'
  }
};

export const superUserSlice = createSlice({
  name: "superUserStore",
  initialState,
  reducers: {
    setFilter(state, action) {
      state.totalData.filter = action.payload
    }
  },
  extraReducers: {
    // Get Total Data Of Super User
    [getTotalData.pending.type]: (state, action) => {

      state.totalData.loading = true;
      state.totalData.error = null;
    },
    [getTotalData.fulfilled.type]: (state, action) => {
      console.log(action.payload, " ===> getTotalData payload");
      state.totalData.loading = false;
      state.totalData.totalCommission = action.payload.totalCommission;
      state.totalData.totalCompaigns = action.payload.totalCompaigns;
      state.totalData.totalRefferals = action.payload.totalRefferals;
      state.totalData.totalRevenue = action.payload.totalRevenue;
      state.totalData.superUser = action.payload.superUser;
      state.totalData.error = null;
    },
    [getTotalData.rejected.type]: (state, action) => {

      console.log(action)
      state.totalData.loading = false;
      state.totalData.error = action.error?.message;
    },
    // Get Total Data Of OverView
    [getDashboardOverview.pending.type]: (state, action) => {

      state.dashboardOverview.loading = true;
      state.dashboardOverview.error = null;
    },
    [getDashboardOverview.fulfilled.type]: (state, action) => {
      state.dashboardOverview.loading = false;
      state.dashboardOverview.error = null;
      state.dashboardOverview.campaigns=action.payload.campaigns;
      state.dashboardOverview.commision=action.payload.commision;
      state.dashboardOverview.commisionGraphData=action.payload.commisionGraphData;
      state.dashboardOverview.refferals=action.payload.refferals;
    },
    [getDashboardOverview.rejected.type]: (state, action) => {
      state.dashboardOverview.loading = false;
      state.dashboardOverview.error = action.error?.message;
    },
    // Get Campaign State Data
    [getCampaignState.pending.type]: (state, action) => {

      state.campaignStateGraph.loading = true;
      state.campaignStateGraph.error = null;
    },
    [getCampaignState.fulfilled.type]: (state, action) => {
      state.campaignStateGraph.loading = false;
      state.campaignStateGraph.error = null;
      state.campaignStateGraph.graphData=action.payload
    },
    [getCampaignState.rejected.type]: (state, action) => {
      state.campaignStateGraph.loading = false;
      state.campaignStateGraph.error = action.error?.message;
    },
    // Get Income Data
    [getIncomeData.pending.type]: (state, action) => {
      state.incomeData.overView.error=null;
      state.incomeData.overView.loading=true;
    },
    [getIncomeData.fulfilled.type]: (state, action) => {
      state.incomeData.overView.error=null;
      state.incomeData.overView.loading=false;
      state.incomeData.overView.availableBalance.value=action.payload.availableBalance
      state.incomeData.overView.totalWithDrawn.value=action.payload.totalWithDrawn
      state.incomeData.overView.recentWithdrawn.value=action.payload.recentWithdrawn
    },
    [getIncomeData.rejected.type]: (state, action) => {
      state.incomeData.overView.error=action.error?.message;
      state.incomeData.overView.loading=false;
    },
  },
});

export const selectTotalData = (state) => state.superUserStore.totalData
export const selectDashboardOverview = (state) => state.superUserStore.dashboardOverview
export const selectCampaignStateGraph = (state) => state.superUserStore.campaignStateGraph
export const selectIncomeData = (state) => state.superUserStore.incomeData

export const { setFilter } = superUserSlice.actions;


export default superUserSlice.reducer;
