import React, { useState } from "react";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import {
  TotalCommission,
  TotalCompaigns,
  TotalReferrals,
} from "../../../components/Icons";
import { useSelector } from "react-redux";
import { selectTotalData } from "../../../store/slices/SuperuserSlice";
import { useTranslation } from "react-i18next";

const intervals = ["This week", "This month", "This year"];

const Overview = ({ className }) => {
  const totalData = useSelector(selectTotalData);
  console.log(totalData, " ===> total Data");
  const { t } = useTranslation("translation");

  const items = [
    {
      title: t("total_campaigns"),
      counter: totalData.totalCompaigns,
      icon: <TotalCompaigns />,
      value: 37.8,
      background: "#DAF8FF",
      chartColor: "#83BF6E",
      data: [
        {
          name: "1",
          earning: 500,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1100,
        },
        {
          name: "4",
          earning: 900,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
    {
      title: t("total_referrals"),
      counter: totalData.totalRefferals,
      icon: <TotalReferrals />,
      value: -37.8,
      background: "#F0FAEB",
      chartColor: "#2A85FF",
      data: [
        {
          name: "1",
          earning: 1300,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1000,
        },
        {
          name: "4",
          earning: 500,
        },
        {
          name: "5",
          earning: 800,
        },
        {
          name: "6",
          earning: 1100,
        },
        {
          name: "7",
          earning: 900,
        },
      ],
    },
    {
      title: t("total_commission"),
      counter: totalData.totalCommission,
      icon: <TotalCommission />,
      value: 37.8,
      background: "#F1EFFE",
      chartColor: "#8E59FF",
      data: [
        {
          name: "1",
          earning: 1200,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1300,
        },
        {
          name: "4",
          earning: 600,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
    {
      title: t("Total Free Subscriptions"),
      counter: totalData?.superUser?.freeSubAssinged,
      icon: <TotalReferrals />,
      value: -37.8,
      background: "#F0FAEB",
      chartColor: "#2A85FF",
      data: [
        {
          name: "1",
          earning: 1300,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1000,
        },
        {
          name: "4",
          earning: 500,
        },
        {
          name: "5",
          earning: 800,
        },
        {
          name: "6",
          earning: 1100,
        },
        {
          name: "7",
          earning: 900,
        },
      ],
    },
    {
      title: t("Subscription Used"),
      counter: totalData?.superUser?.freeSubUsed,
      icon: <TotalCommission />,
      value: 37.8,
      background: "#F1EFFE",
      chartColor: "#8E59FF",
      data: [
        {
          name: "1",
          earning: 1200,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1300,
        },
        {
          name: "4",
          earning: 600,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
  ];

  return (
    <div className={styles.list}>
      {items?.map((x, index) => (
        <div
          className={styles.item}
          key={index}
          style={{ backgroundColor: x?.background }}
        >
          <div className={styles.icon}>{x?.icon}</div>
          <div className={styles.line}>
            <div className={styles.details}>
              <div className={styles.category}>
                {x?.title}
                <Tooltip
                  className={styles.tooltip}
                  title={t('small_desc"')}
                  icon="info"
                  place="right"
                />
              </div>
              <div className={styles.counter}>{x?.counter}</div>
            </div>
            <Chart className={styles.chart} item={x} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Overview;
