import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectedLanguage = () => {
  const { t, i18n } = useTranslation();

  const languages = [
    { id: 0, name: t('english'), locale: 'en' },
    { id: 1, name: t('spanish'), locale: 'es' },
  ];

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  return (
    <div>
      <label htmlFor="languageDropdown">{t('select_lang')}:</label>
      <select
        id="languageDropdown"
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        {languages.map((language) => (
          <option key={language?.id} value={language?.locale}>
            {language?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectedLanguage;
