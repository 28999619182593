import AES from "crypto-js/aes";
import CryptoJS from "crypto-js/core";
import { USER_LOCAL } from "../constants/user";

const phrase = "SuperDashboard";

const encryption = {
  encrypt(phraseToEncrypt) {
    return AES.encrypt(phraseToEncrypt, phrase).toString();
  },
  decrypt(ciphertext) {
    return AES.decrypt(ciphertext, phrase).toString(CryptoJS.enc.Utf8);
  },
};

export const encryptAndSave = (key, value) => {
  if (!key) {
    return null;
  }

  try {
    const encryptedValue = encryption.encrypt(value);
    localStorage.setItem(key, `"${encryptedValue}"`);
    return true;
  } catch (e) {
    console.log("e", e);
    return null;
  }
};

export const loadDecryptedValue = (key) => {
    if (!key) {
      return null;
    }

    try {
      let item;
      if (key === USER_LOCAL.LOCAL) {
        item = localStorage.getItem(key);
      }
      if (!item) return null;
      const encryptedResult = encryption.decrypt(JSON.parse(item));
      return JSON.parse(encryptedResult||"{}");
    } catch (e) {
      return null;
    }
  }