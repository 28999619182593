import React, { useState } from 'react';
import Select from 'react-select';
import Image from '../../Image';
import Icon from '../../Icon';

const data = [
  { value: 'English', label: 'English',img: '/images/uk.svg',  },
  { value: 'Spanish', label: 'Spanish',img: '/images/spanish.svg',  },
];

function  LanguageSelect() {
  const [selectedValue, setSelectedValue] = useState("English")
  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption.value)
    console.log(`Selected: ${selectedOption.label}`);
  };

  return (
      <Select
      // menuIsOpen={true}
        options={data}
        onChange={handleChange}
        isSearchable={false}
        // className="custom-select"
        classNamePrefix="custom-select"
        placeholder={
          <Image src={"/images/uk.svg"}  srcDark={"/images/uk.svg"} fill alt="email-profile" />
        }
        formatOptionLabel={data => (
          <div className='language'>
            <div className='ImgDiv'>
              {selectedValue == data.value && <div className='iconCheck'>
              <span>
                <Icon name="check" fill="white"  className="w-2 h-2"/>
              </span>
              </div>}
            <figure className="">
              <Image src={data.img}  srcDark={data.img} fill alt="email-profile" />
            </figure>
            </div>
              <span className='value'>{data.value}</span> 

          </div>
        )}
      />
  );
}
 export default LanguageSelect;

// import Select from "react-select";
// import Image from "next/image";

// const LanguageSelect = () => {
//   const data = [
//     {
//       img:"https://lokichatdev.blob.core.windows.net/images/avatar-1.jpg",
//       value:"Wilson Westervelt",
//       email:"anthony1@gmail.com"
//     },
//     {
//       img:"https://lokichatdev.blob.core.windows.net/images/avatar-2.jpg",
//       value:"Tiana Bothman",
//       email:"anthony2@gmail.com",
//     },
//     {
//       img:"https://lokichatdev.blob.core.windows.net/images/avatar-3.jpg",
//       value:"Roger Dias",
//       email:"anthony3@gmail.com"
//     },
//   ];

//   const customStyles = {
//     placeholder: (provided) => ({
//       ...provided,
//       // color: "#7F8FA5",
//       whiteSpace: "nowrap",
//       overflow: "hidden",
//       textOverflow: "ellipsis",
//     }),
//   };
//   return (
//     <div className="relative mb-3">
//       <Select
//       // menuIsOpen={true}
//         // closeMenuOnSelect={false}
//         placeholder="Email Address"
//         options={data}
//         className="selects"
//         isClearable
//         classNamePrefix="select-dropdown"
//         formatOptionLabel={data => (
//           <div className="flex gap-3 items-center react-select">
//             <figure className="w-10 h-10 rounded-full relative">
//               <Image src={data.img} fill className="w-full h-full rounded-full" alt="email-profile" />
//             </figure>
//             <div className="flex flex-col">
//               <span className="text-sm">{data.value}</span>
//               <p className="text-xs text-n-4">{data.email}</p>
//             </div>
//           </div>
//         )}
//         styles={customStyles}
//       />
//       <span>
//       {/* <Icon className={`absolute top-3.5 left-4 fill-n-4/50 pointer-events-none transition-colors`} name={"email"}/> */}
//       </span>
//     </div>
//   );
// };

// export default LanguageSelect;
