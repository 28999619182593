import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Item.module.sass";
import { useDispatch } from "react-redux";
import { readNotificationsList } from "../../../../store/actions/notification.action";

const Item = ({ className, item, onClose }) => {

  const dispatch = useDispatch()

  const readNotificationHandler = (notificationItem) => {
    onClose()
    dispatch(readNotificationsList({ id: notificationItem._id }));
  }

  return (
    <Link
      className={cn(styles.item, { [styles.new]: (item?.isRead == false) }, className)}
      to={item?.url}
      onClick={()=>{readNotificationHandler(item)}}
    >
      <div className={cn(styles.avatar, { [styles.online]: item?.online })}>
        <img src={item?.from?.profileUrl ?? "/images/content/avatar.jpg"} alt="Avatar" />
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>{item?.title}</div>
          <div className={styles.time}>{item?.time}</div>
        </div>
        <div className={styles.content}>{item?.message}</div>
      </div>
    </Link>
  );
};

export default Item;
