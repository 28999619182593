import { HttpService } from './base.service'

class NotificationsService extends HttpService {
    prefix
    constructor() {
        super();
        this.prefix = "notification";
    }

    index = () => this.get(this.prefix + '/index');
    update = (data) => this.post(this.prefix + '/update', data);
    read = (data) => this.post(this.prefix + '/read', data);
}

export const notificationsService = new NotificationsService();