import React, { useState } from "react";
import styles from "./Table.module.sass";
import { CompaignsTable } from "../../../mocks/compaignsTable";
import { Delete, Edit } from "../../../components/Icons";
import { toast } from "react-hot-toast";
import Notify from "../../../components/Notify";
import { Link } from "react-router-dom";
import CreateCompaginsModal from "../../../components/CreateCompaignsModal";
import Modal from "../../../components/Modal";
import { deleteCampaign } from "../../../store/actions/compaign.actions";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/NoDataFound";
import dayjs from "dayjs";
import { selectTotalData } from "../../../store/slices/SuperuserSlice";
import { getDashboardOverview } from "../../../store/actions/superuser.action";
import { useTranslation } from "react-i18next";
import 'dayjs/locale/es';
import { i18n } from "../../../services/i18n";

const Table = ({data,loading,setApiCall}) => {
  const { t:lt } = useTranslation('translation');
  const [clean, setClean] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  // const formatOnlyDate = (datestring) => {
  //   const date = dayjs(datestring);
  //   return date.format('DD MMM YYYY');
  // }


const formatOnlyDate = (datestring, locale = i18n.language || 'en') => {
  const date = dayjs(datestring).locale(locale);
  return date.format('DD MMM YYYY');
}


  const handleClickClear = (t) => {
    setClean(true);
    toast.dismiss(t.id);
};
const dispatch=useDispatch()
const totalData = useSelector(selectTotalData)

const handleEditClick = (item) => {
  setSelectedItem(item);
  setVisibleModal(true);
};

  return (
    <div className={styles.wrapper}>
     <table className={styles.Table}>
      <thead className={styles.Thead}>
  <tr className={styles.Row}>
    <th scope="col" className={styles.head}>{lt('title')}</th>
    <th scope="col" className={styles.head}>{lt('referral_count')}</th>
    <th scope="col" className={styles.head}>{lt('total_commission')}</th>
    <th scope="col" className={styles.head}>{lt('action')}</th>
  </tr>
  </thead>
  <tbody>
      {

      (loading && !(data?.length>0))  ? <tr>
          <td colSpan={5}><Loader/></td>
        </tr>
        :
        data?.length>0 ? data?.map((item, index) => (
        <tr className={styles.Row} key={index}>
        <td className={styles.Td} data-label={lt('compaign_name')}>
        <Link to={`/compaigns/${item?.referralCode}`}>
          <p className={styles.name}>{item?.title}</p>
        {/* <Link to="/compaigns-name"> */}
        <span className={styles.date}>{formatOnlyDate(item?.createdAt)}</span>
        </Link>
        </td>
        {console.log("ITEM HERE" , item)}
        <td className={styles.Td} scope="row" data-label={lt('referral_count')}>{item?.specificRecordCount ?? 0}</td>
        <td className={styles.Td} scope="row" data-label={lt('total_subscrip')}>{item?.amount??0}</td>
        <td className={styles.Td} scope="row" data-label={lt('total_commission')}>{item?.commission??0}</td>
        <td className={styles.Td} scope="row" data-label={lt('action')}>
          <div className={styles.actions}>
            <span className={styles.edit}   onClick={() => handleEditClick(item)}><Edit/></span>
            <span className={styles.delete}   
             onClick={() =>
              toast((t) => (
                  <Notify
                    className={styles.Notify}
                      iconDelete
                      // iconCheck
                  >
                      <div>
                          {lt('want_del')}
                      </div>
                      <div
                      className={styles.toast}
                       >
                          <button
                             className={styles.cancelbtn}
                              onClick={() =>
                                  toast.dismiss(t.id)
                              }
                          >
                             {lt('cancel')}
                          </button>
                          <button
                            className={styles.yesbtn}
                              onClick={async() =>
                                {
                                  await dispatch(deleteCampaign(item._id))
                                  await dispatch(getDashboardOverview({filter:totalData?.filter}))
                                  handleClickClear(t)
                                  setApiCall(true)
                                }
                              }
                          >
                              {lt('yes')}
                          </button>
                      </div>
                  </Notify>
              ))
          }
            ><Delete/></span>
          </div>
        </td>
      </tr>
      )): <tr><td colSpan={5}><NoDataFound/></td></tr>}

  </tbody>
</table>
<Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        
       <CreateCompaginsModal setVisibleModal={setVisibleModal} selectedItem={selectedItem} setApiCall={setApiCall}/>
      </Modal>
    </div>
  );
};

export default Table;
