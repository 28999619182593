import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import { Download, LeftArrow, LogOut } from "../../Icons";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOCAL } from "../../../constants/user";
import { loadDecryptedValue } from "../../../utils/encryption";
import { getUserById } from "../../../store/actions/auth.actions";
import { useTranslation } from "react-i18next";
import {useImage, Img} from 'react-image'

// const data=[
//     {
//         name:"Switch to Loki Chat",
//         onClick:()=>{

//         }
//     },
//     // {
//     //     icon:<Download/>,
//     //     name:"New version available",
//     // },
//     {
//         icon:<LogOut/>,
//         name:"Log out",
//         onClick:()=>{
//         }
//     }
// ]


const User = ({ className }) => {
    const { t } = useTranslation('translation');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setdata] = useState([
        {
            name: t('switch_lokiChat'),
            onClick:()=>{
                window.location.replace(`${config.frontEndUrl}/chat`)
            }
        },
        // {
        //     icon:<Download/>,
        //     name:"New version available",
        // },
        {
            icon:<LogOut/>,
            name: t('log_out'),
            onClick:()=>{
                localStorage.clear();
                window.location.replace(`${config.frontEndUrl}/sign-in`)
            }
        }
    ])

    useEffect(()=>{
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        console.log(sessions," user Sessions");
        if(sessions && sessions?.userId){
           dispatch(getUserById({id:sessions?.userId}))
        }
    },[])

    const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);

    const [visible, setVisible] = useState(false);

    const user = useSelector(state=>state.authStore.localUser)
    console.log(user, user?.profileUrl, " ===> localUser ");

    // const {src} = useImage({srcList:user?.profileUrl})

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
                className={cn(styles.user, className, {
                    [styles.active]: visible,
                })}
            >
                <button
                    className={styles.head}
                    onClick={() => setVisible(!visible)}
                >
                    <img src={user && user.profileUrl ? user.profileUrl : "/images/content/avatar.jpg"} alt="Avatar" />
                    {/* <img src={user?.profileUrl ?? "/images/content/avatar.jpg"} alt="Avatar" /> */}
                    {/* <img src={user?.profileUrl ? src : "/images/content/avatar.jpg"} /> */}
                    {/* <Img src="https://lokichatdev.blob.core.windows.net/devloki/b3d6c73d-d827-412b-9029-6a118803d41e" crossorigin="anonymous" /> */}
                </button>
                <div className={styles.body}>
                   <div className={styles.menu}>
                    <figure className={styles.figure}>
                    <img
                     className={styles.pic}
                     src={user?.profileUrl ?? "/images/profile.png"}
                     alt="Loki"
                     width={60}
                     height={60}
                     onError={(e)=>{console.log(e, " ====> error occured in img tage");}}
                    />
                    <div className={styles.dot}></div>
                    </figure>
                    <h2 className={styles.name} onClick={()=>{
                        console.log(sessions, ' ===> Selected User Session');
                    }} >{user?.userName ?? user?.email ?? 'Unknown'}</h2>
                   </div>
                <div className={styles.linkMenu}>
                    {
                        data && data?.length>0 && data?.map((item,i)=>(
                            <Link onClick={item?.onClick} className={`${item?.name==="Switch to Loki Chat" ? styles.switchlink: styles.link } `} key={i}>
                                {item?.name==="Switch to Loki Chat"? <span className={styles.whiteDot}></span> :<span>{item?.icon}</span>}
                                {item?.name==="Switch to Loki Chat"? <span className={styles.LeftArrow}><LeftArrow/></span>:""}
                                <p className={styles.linkName}>{item?.name}</p>
                            </Link>
                        ))
                    }
                   
                </div>

                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default User;
