
import { initialState as authInitialState } from '../slices/AuthSlice'
import { initialState as compaignInitialState } from '../slices/CompaignSlice'
export const migrations = {
    1.0: (state) => {
      // migration clear out device state
      return {
        ...state,
        authStore: authInitialState,
        compaignStore:compaignInitialState
      }
    },
  }