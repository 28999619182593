import axios from "axios";
import Config from "../config/index";
import { encryptAndSave, loadDecryptedValue } from "../utils/encryption";
import { USER_LOCAL } from "../constants/user";
import { ROUTES } from "../constants/routes";
import config from "../config/index";
import { getUserBrowserInfo, getUserIP } from "../utils/user";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.request.use(
  (config) => {
    if (!config.url?.includes("/auth")) {
      const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
      console.log('sessions', sessions);
      if (!sessions) {
        // clearLogoutStorage();
        localStorage.clear();
        // window.location.href = ROUTES.SIGNIN;
        window.location.replace(`${config.frontEndUrl}/sign-in`)
      }
      config.headers["Authorization"] = `Bearer ${sessions.token}`
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403) {
      if (window.location.pathname !== ROUTES.SIGNIN) {
        // clearLogoutStorage();
        localStorage.clear();
        // window.location.href = ROUTES.SIGNIN;
        window.location.replace(`${config.frontEndUrl}/sign-in`)
      }
    } else if (
      error.response.status === 405 &&
      !error.config.url?.includes("/login") &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(async function (resolve, reject) {
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        const browserInfo = getUserBrowserInfo();
        const ipAddress = await getUserIP();
        const refreshTokenPayload = {
          refreshToken: sessions.refreshToken,
          userId: sessions.userId,
          ipAddress: ipAddress,
          os: browserInfo.os,
          browserName: browserInfo.browserName,
          browserVersion: browserInfo.browserVersion,
        };
        axios
          .post(Config.baseApiUrl + "/auth/refresh-token", refreshTokenPayload)
          .then(({ data }) => {
            const updatedResponse = {
              refreshToken: data.data.refreshToken,
              userId: sessions.userId,
              token: data.data.token,
            };
            encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(updatedResponse));
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.token;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            console.log("erere", err);
            processQueue(err, null);
            if (window.location.pathname !== ROUTES.SIGNIN) {
            //   clearLogoutStorage();
              localStorage.clear();
              window.location.href = ROUTES.SIGNIN;
            }
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export class HttpService {
  constructor() {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  }

  static setToken(token) {
    console.log("setting token:", token);
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  get(url, params) {
    return axios.get(`${Config.baseApiUrl}/${url}`, {
      params,
      cancelToken: this.source.token,
    });
  }

  post(url, body, options = {}) {
    return axios.post(`${Config.baseApiUrl}/${url}`, body, {
      ...options,
      cancelToken: this.source.token,
    });
  }

  delete(url, params, data) {
    return axios.delete(`${Config.baseApiUrl}/${url}`, { params, data });
  }

  put(url, body, params) {
    return axios.put(`${Config.baseApiUrl}/${url}`, body, {
      ...params,
      cancelToken: this.source.token,
    });
  }

  updateCancelToken() {
    this.source = this.CancelToken.source();
  }

  cancel() {
    this.source.cancel("Explicitly cancelled HTTP request");
    this.updateCancelToken();
  }
}