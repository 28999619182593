import React, { useState } from "react";
import cn from "classnames";
import styles from "./Theme.module.sass";
import useDarkMode from "use-dark-mode";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";

const Theme = ({ className, visibleSidebar,visible }) => {
  const darkMode = useDarkMode(false);
  const [lightDark, setLightDark] = useState(false);
  


console.log(darkMode,"darkMode");
const { t } =useTranslation('translation');

  return (
    <label
      className={cn(className, styles.theme, { [styles.wide]: visibleSidebar })}
    >
      <input
        className={styles.input}
        checked={darkMode.value}
        onChange={darkMode.toggle}
        type="checkbox"
      />
        {/* <div className={cn(styles.LightDark, className, { [styles.active]: visible, })}>
          {
            lightDark?   <span onClick={()=>{setLightDark(!lightDark)}}>
            <Icon name="sun" size="24" />
           </span>:
          <span onClick={()=>{setLightDark(!lightDark)}}>  
            <Icon name="moon" size="24" />
          </span>
          }
        
        </div> */}
      <span  className={cn(styles.inner, className, { [styles.active]: visible, })}>
        <span className={cn(styles.box, className, { [styles.active]: visible, })}>
          <Icon name="sun" size="24" />
         <span className={cn(styles.lighDark,{ [styles.active]: visible, })}>{t('light')}</span>
        </span>
        <span className={cn(styles.box, className, { [styles.active]: visible, })}>
          <Icon name="moon" size="24" />
          <span className={cn(styles.lighDark, { [styles.active]: visible, })}>{t('dark')}</span>
        </span>
      </span>
    </label>
  );
};

export default Theme;
