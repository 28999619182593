import React, { useEffect, useState } from "react";
import styles from "./TrafficChannel.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useDarkMode from "use-dark-mode";
import campaignService from "../../../services/compaign.service";
import { loadDecryptedValue } from "../../../utils/encryption";
import { USER_LOCAL } from "../../../constants/user";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";

const TrafficChannel = ({ className }) => {
  const { t } = useTranslation('translation');

  const intervals = [t('last_7days'), t('this_month'), t('this_year')];
  
  const darkMode = useDarkMode(false);
  const [sorting, setSorting] = useState(intervals[0]);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(null); // Initialize as null
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        const response = await campaignService.referralChart({
          creator: sessions.userId,
          filter: sorting,
        });
        setRecord(response?.data?.data?.chartData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sorting]);

  // Create a custom Tooltip for displaying userCount
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.tooltipLabel}>{label}</p>
          <p className={styles.tooltipValue}>{payload[0].value}</p>
        </div>
      );
    }

    return null;
  };
  const dataForChart = record
  ? Object.entries(record)?.map(([date, data]) => ({
      date,
      userCount: data?.reduce((total, item) => total + item?.userCount, 0),
    }))
  : [];

  return (
  <Card
      className={cn(styles.card, className)}
      title={t('referral_stats')}
      classTitle="title-purple"
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.chart}>
        {loading?<Loader/>:record ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={dataForChart}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={darkMode.value ? "#272B30" : "#EFEFEF"}
              />
              <XAxis dataKey="date" label={{ value: t('date'), position: "insideBottom", dy: 10 }} />
              <YAxis allowDecimals={false} label={{ angle: -90, position: "insideLeft" }} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="userCount" fill="#2A85FF" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p className={styles.Loading}>{t('loading')}...</p>
        )}
      </div>
    </Card>
  );
};

export default TrafficChannel;
