import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import { Delete, Edit } from "../../../components/Icons";
import { toast } from "react-hot-toast";
import Notify from "../../../components/Notify";
import { CompaignsNameTable } from "../../../mocks/compaignsNameTable";
import { tr } from "date-fns/locale";
import NoDataFound from "../../../components/NoDataFound";
import Loader from "../../../components/Loader";

const Table = ({record,loading}) => {
  const [clean, setClean] = useState(false);
  const handleClickClear = (t) => {
    setClean(true);
    toast.dismiss(t.id);
};
  return (
    <div className={styles.wrapper}>
     <table className={styles.Table}>
      <thead className={styles.Thead}>
  <tr className={styles.Row}>
    <th scope="col" className={styles.head}>Name</th>
    {/* <th scope="col" className={styles.head}>Email</th> */}
    <th scope="col" className={styles.head}>Subscription Amount</th>
    <th scope="col" className={styles.head}>Your Commission</th>
    <th scope="col" className={styles.head}>Status</th>
  </tr>
  </thead>
  <tbody>
    {loading? <tr><td colSpan={5}><Loader/></td></tr> : record.length>0? record?.map((item, index) => (
        <tr className={styles.Row} key={index}>
        <td className={styles.Td} data-label="Name">
          <div className={styles.wrapperTd}>
            <figure className={styles.figure}>
            <img src={item?.profileUrl ?? "/images/compaigns/table/01.png"} alt="Avatar" className={styles.Image}/>
            </figure>
            <div>
              <p className={styles.Name}>{item?.userName}</p>
            </div>
          </div>
        </td>
        {/* <td className={styles.Td} scope="row" data-label="Referral (count)">{item.email}</td> */}
        {/* <td className={styles.Td} scope="row" data-label="Total Subscription">{index===1? "-": item.amount}</td>
        <td className={styles.Td} scope="row" data-label="Total Commission">{index===1? "-":item.commission}</td> */}
        <td className={styles.Td} scope="row" data-label="Total Subscription">{item?.amount ?? 0}</td>
        <td className={styles.Td} scope="row" data-label="Total Commission">{item?.commission ?? 0}</td>
        <td className={styles.Td} scope="row" data-label="Action">
          {item?.subscription?
            <button className={styles.subscribe}>Subscribed</button>:
            <button className={styles.Logged}>Logged In</button>

          }
        </td>
      </tr>
      )):<tr><td colSpan={5}><NoDataFound/></td></tr>}

  </tbody>
</table>
     
    </div>
  );
};

export default Table;
