import React, { useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";

const Dropdown = ({ className, item, visibleSidebar, setValue, onClose }) => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();

    const handleClick = () => {
        setVisible(!visible);
        setValue(false);
    };

    const Head = () => {
        return (
            <button
                className={cn(
                    styles.head,
                    {
                        [styles.active]: pathname.includes(item.slug),
                    },
                    { [styles.wide]: visibleSidebar }
                )}
                onClick={() => handleClick()}
            >
                {
                    (item.title === "Referrals" || item.title === "Referencias") && <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g clip-path="url(#clip0_8302_27787)">
                      <path d="M8.75 15C11.4424 15 13.625 12.8174 13.625 10.125C13.625 7.43261 11.4424 5.25 8.75 5.25C6.05761 5.25 3.875 7.43261 3.875 10.125C3.875 12.8174 6.05761 15 8.75 15Z" stroke="#B2E899" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M15.0699 5.43271C15.7404 5.24379 16.4437 5.20075 17.1322 5.3065C17.8208 5.41224 18.4787 5.66431 19.0616 6.04572C19.6445 6.42714 20.1389 6.92904 20.5116 7.51763C20.8842 8.10622 21.1264 8.76783 21.2218 9.45789C21.3172 10.148 21.2636 10.8504 21.0646 11.5181C20.8656 12.1857 20.5259 12.8029 20.0683 13.3281C19.6108 13.8534 19.0459 14.2745 18.4119 14.5631C17.7778 14.8517 17.0893 15.001 16.3927 15.0011C17.7167 15.0002 19.0214 15.3182 20.1964 15.9284C21.3713 16.5386 22.382 17.4229 23.1427 18.5065M2 18.5071C2.76133 17.4241 3.77206 16.5401 4.94687 15.9299C6.12167 15.3197 7.42604 15.0012 8.74987 15.0011C10.0737 15.0011 11.3781 15.3195 12.5529 15.9296C13.7278 16.5398 14.7386 17.4236 15.5 18.5065" stroke="#B2E899" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_8302_27787">
                        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                }
                {item.title}
                <Icon name="arrow-down" size="24"  className={styles.Icon}/>
            </button>
        );
    };

    return (
        <div
            className={cn(
                styles.dropdown,
                className,
                { [styles.active]: visible },
                {
                    [styles.active]: pathname.includes(item.slug),
                },
                { [styles.wide]: visibleSidebar }
            )}
        >
            {item.add ? (
                <div
                    className={cn(styles.top, {
                        [styles.active]: pathname.startsWith("/products/add"),
                    })}
                >
                    <Head />
                    <Link
                        className={cn(styles.add, {
                            [styles.active]:
                                pathname.startsWith("/products/add"),
                        })}
                        to="/products/add"
                        onClick={onClose}
                    >
                        <Icon name="plus" size="10" />
                    </Link>
                </div>
            ) : (
                <Head />
            )}
            <div className={styles.body}>
                {Array.isArray(item?.dropdown) && item?.dropdown?.map((x, index) => (
                    <NavLink
                        className={cn(styles.link, {
                            [styles.active]: pathname === x?.url,
                        })}
                        to={x?.url}
                        key={index}
                        onClick={()=>{setValue(false)}}
                        // onClick={onClose}
                    >
                        {x?.title}
                        {x?.counter ? (
                            <div
                                className={styles.counter}
                                style={{ backgroundColor: x?.colorCounter }}
                            >
                                {x?.counter}
                            </div>
                        ) : (
                            <Icon name="arrow-next" size="24" />
                        )}
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default Dropdown;
