import React from 'react'
import styles from "./NoFound.module.sass";
import { useTranslation } from "react-i18next";

const NoDataFound = () => {
  const { t } = useTranslation('translation');
  return (
    <div className={styles.wrapper}>
        <h1>{t('noData_found')}</h1>
    </div>
  )
}

export default NoDataFound