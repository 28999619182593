import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCampaign,
  generateComapign,
  getAllComapign,
  updateCompaignById,
} from "../actions/compaign.actions";

export const initialState = {
  createCompaign: {
    data: {
      data: [],
    },
    loading: false,
    error: null,
  },
  compaigns: {
    data: [],
    totalCount: 0,
    loading: true,
    error: null,
  },
};

export const compaignSlice = createSlice({
  name: "compaignStore",
  initialState,
  reducers: {
    setCampaingsData(state, action) {
      state.compaigns.data = []
    }
  },
    
  extraReducers: {
   // Generate new compagin
    [generateComapign.pending.type]: (state, action) => {
      state.createCompaign.loading = true;
      state.createCompaign.data.data = [];
      state.createCompaign.error = null;
    },
    [generateComapign.fulfilled.type]: (state, action) => {
      const data={...action.payload.data,_id:action.payload.data.id}
      state.compaigns.data = [data,...state.compaigns.data];
      state.createCompaign.loading = false;
      state.createCompaign.error = null;
    },
    [generateComapign.rejected.type]: (state, action) => {
      state.createCompaign.data.data = [];
      state.createCompaign.loading = false;
      state.createCompaign.error = action.error?.message;
    },
    //  update  compagin
    [updateCompaignById.pending.type]: (state, action) => {
      state.compaigns.loading = true;
    },
    [updateCompaignById.fulfilled.type]: (state, action) => {
      const updatedCampaign={...action.payload.data,_id:action.payload.data.id}
      const campaignIndex = state.compaigns.data.findIndex(
        (compaign) =>compaign._id=== updatedCampaign.id
      );
      if (campaignIndex !== -1) {
        // @ts-ignore
        state.compaigns.data[campaignIndex] = updatedCampaign;
      }
      state.compaigns.loading = false;
      state.compaigns.error = null;
    },
    [updateCompaignById.rejected.type]: (state, action) => {
      state.compaigns.loading = false;
      state.compaigns.error = action.error?.message;
    },
   // get all compagin
    [getAllComapign.pending.type]: (state, action) => {
      state.compaigns.loading = true;
      // state.compaigns.data = [];
      state.compaigns.error = null;
    },
    [getAllComapign.fulfilled.type]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.compaigns.data = action.payload.records;
      } else {
        state.compaigns.data = [...state.compaigns.data, ...action.payload.records];
      }
      state.compaigns.totalCount = action.payload.totalRecords;
      state.compaigns.loading = false;
      state.compaigns.error = null;
    },
    
    [getAllComapign.rejected.type]: (state, action) => {
      console.log("rejected");
      // state.compaigns.data = [];
      state.compaigns.loading = false;
      state.compaigns.error = action.error?.message;
    },
    [deleteCampaign.pending.type]: (state, action) => {
      state.compaigns.loading = true;
    },
    [deleteCampaign.fulfilled.type]: (state, action) => {
      state.compaigns.data = [
        ...state.compaigns.data.filter(
          (compaign) => compaign._id != action.payload.data._id
        ),
      ];
      state.compaigns.totalCount =state.compaigns.totalCount-1
      state.compaigns.loading = false;
      state.compaigns.error = null;
    },
    [deleteCampaign.rejected.type]: (state, action) => {
      state.compaigns.loading = false;
      state.compaigns.error = action.error?.message;
    },
  },
});
export const { setCampaingsData } = compaignSlice.actions;

export default compaignSlice.reducer;
