import React, { useState } from "react";
import styles from "./Table.module.sass";
import { toast } from "react-hot-toast";
import { CompaignsNameTable } from "../../../mocks/compaignsNameTable";
import Loader from "../../../components/Loader";
import NoDataFound from "../../../components/NoDataFound";
import { useTranslation } from "react-i18next";

const Table = ({ record, loading }) => {
  const { t } = useTranslation("translation");

  return (
    <div className={styles.wrapper}>
      <table className={styles.Table}>
        <thead className={styles.Thead}>
          <tr className={styles.Row}>
            <th scope="col" className={styles.head}>
              {t("name")}
            </th>
            <th scope="col" className={styles.head}>
              {t("campaign_name")}
            </th>
            <th scope="col" className={styles.head}>
              {t("subscrip_amount")}
            </th>
            <th scope="col" className={styles.head}>
              {t("your_commission")}
            </th>
            <th scope="col" className={styles.head}>
              {t("status")}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={5}>
                <Loader />
              </td>
            </tr>
          ) : record?.length > 0 ? (
            record?.map((item, index) => (
              <tr className={styles.Row} key={index}>
                <td className={styles.Td} data-label={t("name")}>
                  <div className={styles.wrapperTd}>
                    <figure className={styles.figure}>
                      <img
                        src={item?.profileUrl ?? "/images/content/avatar-1.jpg"}
                        alt="Avatar"
                        className={styles.Image}
                      />
                    </figure>
                    <div>
                      <p className={styles.Name}>{item?.userName}</p>
                    </div>
                  </div>
                </td>
                <td
                  className={styles.Td}
                  style={{ whiteSpace: "normal" }}
                  scope="row"
                  data-label={t("referral_count")}
                >
                  <p style={{ wordWrap: "break-word" }}>
                    {item?.campaignId?.title}
                  </p>
                </td>
                {/* <td className={styles.Td} scope="row" data-label="Total Subscription">{index==1? "-": item.amount}</td>
        <td className={styles.Td} scope="row" data-label="Total Commission">{index==1? "-":item.commission}</td> */}
                <td
                  className={styles.Td}
                  scope="row"
                  data-label={t("total_subscrip")}
                >
                  {item?.amount ?? 0}
                </td>
                <td
                  className={styles.Td}
                  scope="row"
                  data-label={t("total_commission")}
                >
                  {item?.commission ?? 0}
                </td>
                <td className={styles.Td} scope="row" data-label={t("action")}>
                  {item?.subscription ? (
                    <button className={styles.subscribe}>
                      {t("subscribed")}
                    </button>
                  ) : (
                    <button className={styles.Logged}>{t("logged_in")}</button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>
                <NoDataFound />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
