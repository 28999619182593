import React, { useState,useEffect } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import { TotalCommission, TotalCompaigns, TotalReferrals } from "../../../components/Icons";
import campaignService from "../../../services/compaign.service";
import { loadDecryptedValue } from "../../../utils/encryption";
import { USER_LOCAL } from "../../../constants/user";
import { getDashboardOverview } from "../../../store/actions/superuser.action";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardOverview, setFilter } from "../../../store/slices/SuperuserSlice";
import { useTranslation } from "react-i18next";


const Overview = ({ className,apiCall,setApiCall }) => {

  const { t } = useTranslation('translation');
  const intervals = [t('this_week'), t('this_month'), t('this_year')];

  const [sorting, setSorting] = useState(intervals[0]);
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch()
  
  
  const overViewDate = useSelector(selectDashboardOverview)
  
  const items = [
    {
      title: t('total_campaigns'),
      // counter: record?.totalCampaignsCountCurrent ?? 0,
      counter: overViewDate?.campaigns ?? 0,
      icon: <TotalCompaigns/>,
      value:record?.totalCampaignsCountPrevious > 0 ?( ((record?.totalCampaignsCountCurrent - record?.totalCampaignsCountPrevious) / record?.totalCampaignsCountPrevious) * 100).toFixed(2) :100 ,
      background: "#DAF8FF",
      chartColor: "#83BF6E",
      data: [
        {
          name: "1",
          earning: 500,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1100,
        },
        {
          name: "4",
          earning: 900,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
    {
      title: t('total_referrals'),
      // counter:  record?.usersWithSameCampaignsCurrent ?? 0,
      counter: overViewDate?.refferals ?? 0,
      icon: <TotalReferrals/>,
      value:record?.usersWithSameCampaignsPrevious > 0 ?( ((record?.usersWithSameCampaignsCurrent - record?.usersWithSameCampaignsPrevious) / record?.usersWithSameCampaignsPrevious) * 100).toFixed(2) : 100 ,
      background: "#F0FAEB",
      chartColor: "#2A85FF",
      data: [
        {
          name: "1",
          earning: 1300,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1000,
        },
        {
          name: "4",
          earning: 500,
        },
        {
          name: "5",
          earning: 800,
        },
        {
          name: "6",
          earning: 1100,
        },
        {
          name: "7",
          earning: 900,
        },
      ],
    },
    {
      title: t('total_commission'),
      // counter: "64k",
      counter: overViewDate?.commision ?? 0,
      icon: <TotalCommission/>,
      value: 37.8,
      background: "#F1EFFE",
      chartColor: "#8E59FF",
      data: [
        {
          name: "1",
          earning: 1200,
        },
        {
          name: "2",
          earning: 800,
        },
        {
          name: "3",
          earning: 1300,
        },
        {
          name: "4",
          earning: 600,
        },
        {
          name: "5",
          earning: 1300,
        },
        {
          name: "6",
          earning: 800,
        },
        {
          name: "7",
          earning: 1000,
        },
      ],
    },
  ];
  useEffect(() => {
    const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
    const fetchData = async () => {
      try {
        const response = await campaignService.count({creator:sessions?.userId,filter:sorting});
        
        setRecord(response?.data?.data)

        setApiCall(false)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData(); // Call the async function here
  
  }, [sorting,apiCall]);


  useEffect(() => {
    dispatch(setFilter(sorting))
    dispatch(getDashboardOverview({filter:sorting}))
  }, [sorting]);

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title={t('overview')}
        classTitle="title-purple"
        head={
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
        }
      >
        <div className={styles.overview}>
          <div className={styles.list}>
            {items?.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x?.background }}
              >
                  <div className={styles.icon}>{x?.icon}</div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x?.title}
                      <Tooltip
                        className={styles.tooltip}
                        title={t('small_desc')}
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x?.counter}</div>
                    <div className={styles.indicator}>
                      {/* <Balance className={styles.balance} value={x.value} /> */}
                      {/* <span>{sorting}</span> */}
                    </div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
