import React, { useEffect, useRef, useState } from 'react'
import styles from "./CompaignsName.module.sass"
import { Copy } from '../../components/Icons'
import Notify from '../../components/Notify'
import { toast } from 'react-hot-toast'
import { InviteModal } from '../../components/InviteModal'
import Table from './Table'
import Form from '../../components/Form'
import Modal from '../../components/Modal'
import { useParams } from 'react-router-dom';
import campaignService from '../../services/compaign.service'
import CopyToClipboard from 'react-copy-to-clipboard'
import {size } from "lodash";
import config from '../../config'


const CompaignsName = () => {
    const [modalShow, setModalShow] = useState(false);
    const [search, setSearch] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
const [copyButtonDisabled, setCopyButtonDisabled] = useState(false);
    const { id } = useParams();
    const [link, setLink] = useState(
      `${config.frontEndUrl}/affiliate/${id}`
  );
  const onCopy = (isCode = false) => {
  
    setCopyButtonDisabled(true)
      setCopied(true);
      setTimeout(() => {
        toast((t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{isCode == true ? 'Code' : 'Link'} copied</div>
          </Notify>
        ));
        setCopyButtonDisabled(false); // Re-enable the copy button after showing the toast
      }, 1000);
  };
  let copyButtonRef = useRef(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [record, setRecord] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1});
  const handleSubmit = (e) => {
    alert();
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await campaignService.indexByCode({code:id,search:search,page: paginationInfo.currentPage});
        if (paginationInfo.currentPage === 1) {
          setRecord(response?.data?.data.records); 
        } else {
          setRecord((prevRecord) => [...prevRecord, ...response?.data?.data.records]);
        }
        setCampaignName(response?.data?.data.campaign)
        setTotalCount(response?.data?.data.totalRecords)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error fetching data:", error);
      }
    };
    fetchData(); 
  }, [paginationInfo,search]);

  // `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${ROUTES.SIGNIN}?referralCode=${user?.data?.referralCode}`
  return (
    <section className={styles.section}>
        <div className={styles.wrapper}>
            <div className={styles.innerContent}>
                <h2 className={styles.name}>{campaignName?.title}</h2>
                <span className={styles.description}>{campaignName?.description}</span>
            </div>
             <div className={styles.linkDiv}>
              <div className={styles.compaignsDetail}>
                <div>
                <h2 className={styles.link}>Campaign Details</h2>
                <p className={styles.text}>Please find the referral code & share with your friends & earn more.</p>
                </div>
                <div className={styles.compaignsLink}>{campaignName?.referralCode}
                   <span className={styles.copyIcon}>
                   <CopyToClipboard text={campaignName?.referralCode} onCopy={()=>{onCopy(true)}}>
                        <Copy/>
                    </CopyToClipboard></span> 
                </div>

                </div>
                <div className={styles.inputDiv}>
                    <input placeholder={link} value={link}className={styles.input}/>
                    <CopyToClipboard text={link} onCopy={onCopy}>
                        <button disabled={copyButtonDisabled}  className={styles.Copy} ref={copyButtonRef} type="button">
                        <Copy/>
                        </button>
                    </CopyToClipboard>  
                </div>
                <button onClick={() => setVisibleModal(true)}  className={styles.inviteBtn}>Invite Friends</button>
            </div>   
        </div>
        {/* <div className={styles.wrapper}>
             <div className={styles.linkDiv}>
                <h2 className={styles.link}>Referral Code</h2>
                <div className={styles.inputDiv}>
                    <input placeholder={campaignName?.referralCode} value={campaignName?.referralCode}className={styles.input}/>
                    <CopyToClipboard text={campaignName?.referralCode} onCopy={onCopy}>
                        <button disabled={copyButtonDisabled}  className={styles.Copy} ref={copyButtonRef} type="button">
                        <Copy/>
                        </button>
                    </CopyToClipboard>  
                    
                </div>
            </div>   
        </div> */}

        <div className={styles.fromCompaigns}>
              <h3 className={styles.compaigns}>Referrals</h3>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search by Referrals"
              type="text"
              name="search"
              icon="search"
            />
            </div>
       <Table record={record}  loading={loading}/>
       {
        totalCount>10&&
        <div className={styles.loadMoreDiv}>
        <button
             type="button"
             className={styles.loadMore}
             disabled={totalCount == size(record)}
             onClick={() => {
               setPaginationInfo((prev) => ({
                 ...prev,
                 currentPage: prev.currentPage + 1,
               }));
             }}
           >
             Load More
           </button>
        </div>
       }
       <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <InviteModal id={id} />
      </Modal>
    </section>
  )
}

export default CompaignsName