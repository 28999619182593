import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Users from "../../../components/Users";
import Chart from "./Chart";
import { loadDecryptedValue } from "../../../utils/encryption";
import { USER_LOCAL } from "../../../constants/user";
import campaignService from "../../../services/compaign.service";
import { useTranslation } from "react-i18next";

const Overview = ({ className }) => {
  const { t } = useTranslation('translation');

  const intervals = [t('last_7days'),t('last_28days'), t('last_14days')];

  const [sorting, setSorting] = useState(intervals[0]);
  const [loading, setLoading] = useState(false);
  const [totalReferral, setTotalReferral] = useState();
  const [todaysReferral, setTodaysReferral] = useState();
  const [record, setRecord] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        const response = await campaignService.newReferralList({creator:sessions.userId});
        setRecord(response?.data?.data.records); 
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData(); 
  
  }, []);


  const [graphLoading, setgraphLoading] = useState(false);
  const [graphRecord, setGraphRecord] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setgraphLoading(true)
      try {
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        const response = await campaignService.referralGraph({creator:sessions.userId,filter:sorting});
   
        setGraphRecord(response?.data?.data?.data);  
         setTodaysReferral(response?.data?.data?.todayReferrals);
         setTotalReferral(response?.data?.data?.totalReferrals); 
        setgraphLoading(false)
      } catch (error) {
        setgraphLoading(false)
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData(); 
  
  }, [sorting]);
  return (
    <Card
      className={cn(styles.card, className)}
      title={t('total_referrals')}
      classTitle={cn("title-red", styles.cardTitle)}
      classCardHead={styles.cardHead}
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={intervals}
          small
        />
      }
    >
      <div className={styles.overview}>
        <div className={styles.details}>
          <div className={cn("h4", styles.title)}>{totalReferral?? 0} {totalReferral>1? t('referrals') :('referral')}</div>
        </div>
        <Chart graphRecord={graphRecord} graphLoading={graphLoading} />
        <p className={styles.welcome}>{t('welcome')} <span className={styles.refferal}>{record?.length?? 0} {t('new_referrals')}</span></p>          
        <Users className={styles.users} record={record} />
      </div>
    </Card>
  );
};

export default Overview;
