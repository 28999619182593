import React, { useState } from "react";
import styles from "./Table.module.sass";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import cn from "classnames";
import { WithdrawlHistory } from "../../../mocks/withdrawlHistory";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import axios from "axios";

const Table = ({data}) => {

  const { t } = useTranslation('translation');

  const intervals = [t('last_7days'), t('this_month'),t('all_time') ];

  const [sorting, setSorting] = useState(intervals[0]);
 
  const getPlanAmount = (sub, planId) => {
    const plan = sub?.plans?.find(i=>i?._id?.toString() == planId?.toString())
    return Math.floor(Number(plan?.price)/5) ?? 0
  }

  const getStripeConnectLoginLink = async () => {
    try {
      const { data } = await axios.get(
        `${config.baseApiUrl}/stripe/getConnectAccountLoginLink`
      );
      console.log(data, " ===> data to Login Stripe");
      window.open(data?.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };
  

  return (
    <div className={styles.wrapper}>
    <Card
     className={cn(styles.card,)}
     title={t('withdrawal_history')}
     classTitle="title-purple"
     classCardHead={styles.classCardHead}
    //  head={
    //    <Dropdown
    //      className={styles.dropdown}
    //      classDropdownHead={styles.dropdownHead}
    //      value={sorting}
    //      setValue={setSorting}
    //      options={intervals}
    //      small
    //    />
    //  }
    />
      <table className={styles.Table}>
      <thead className={styles.Thead}>
  <tr className={styles.Row}>
    <th scope="col" className={styles.head}>{t('date')}</th>
    <th scope="col" className={styles.head}>{t('transaction')}</th>
    <th scope="col" className={styles.head}>{t('user')}</th>
    <th scope="col" className={styles.head}>{t('campaign')}</th>
    <th scope="col" className={styles.head}>{t('total_amount')}</th>
    <th scope="col" className={styles.head}>{t('actions')}</th>
  </tr>
  </thead>
  <tbody>
    { data && data?.length > 0 && data?.map((item, index) => (
        <tr className={styles.Row} key={index}>
        <td className={styles.Td} scope="row" data-label={t('date')}>{item?.createdAt}</td>
        <td className={styles.Td} scope="row" data-label={t('transaction')}><button className={styles.withdrawl}>{t('withdral')}</button></td>
        <td className={styles.Td} scope="row" data-label={t('transaction')}>{item?.user?.userName ?? item?.user?.email}</td>
        <td className={styles.Td} scope="row" data-label={t('transaction')}>{item?.campaignId?.title}</td>
        <td className={styles.Td + ' ' + styles.TdAmount}  scope="row" data-label={t('total_amount')}>{(Number(item?.plan?.price) / 5) ?? 0}</td>
        {/* <td className={styles.Td + ' ' + styles.TdAmount}  scope="row" data-label={t('total_amount')}>{getPlanAmount(item?.subscription, item?.plan)}</td> */}
        <td onClick={(()=>getStripeConnectLoginLink())} style={{cursor: 'pointer'}} className={`${styles.Td} cursor-pointer`} scope="row" data-label={t('actions')}><span className={styles.TdActions}>{"View Receipt"}</span></td>
      </tr>
      ))}

  </tbody>
</table>
     
    </div>
  );
};

export default Table;
