import { HttpService } from './base.service';

class AuthService extends HttpService {
    prefix
    constructor() {
        super();
        this.prefix = "auth";
    }

    login(data) {
        return this.post(this.prefix + '/login', data);
    }

    getUserById(data) {
        return this.get(this.prefix + `/getUserById/${data?.id}`);
    }
}

const authService = new AuthService();

export default authService;
