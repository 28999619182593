import React, { useState } from "react";
import styles from "./Header.module.sass";
import Messages from "./Messages";
import User from "./User";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../SelectLanguage";
import LanguageSelect from "./LanguageSelect";

const Header = ({ onOpen,visible,setVisible }) => {
  // const [visible, setVisible] = useState(false);
  const { t } = useTranslation('translation')
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  const user = useSelector(state=>state.authStore.localUser)

  return (
    <header className={cn(styles.header,{ [styles.active]: visible})}>
      {/* <button className={styles.burger} onClick={() => handleClick()}></button> */}
    <h1 className={styles.heading}>{t('hello')} { user?.userName ?? user?.email ?? 'Super User'}</h1>
      <div className={styles.control}>
        <div>
        {/* <LanguageSwitcher/> */}
        <LanguageSelect/>
        </div>
        <Messages className={styles.messages} />
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
