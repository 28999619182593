import { useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Messages.module.sass";
import Item from "./Item";
import { useDispatch, useSelector } from "react-redux";
import { getMineNotifications } from "../../../store/actions/notification.action";
import { selectNotifications } from "../../../store/slices/NotificationSlice";
import { loadDecryptedValue } from "../../../utils/encryption";
import { USER_LOCAL } from "../../../constants/user";

const messages1 = [
    {
        title: "Jarret Waelchi",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-1.jpg",
        time: "03:30PM",
        new: true,
        url: "/message-center",
    },
    {
        title: "Orval Casper",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-2.jpg",
        time: "11:59AM",
        online: true,
        url: "/message-center",
    },
    {
        title: "Michel Emard",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-3.jpg",
        time: "09:30AM",
        new: true,
        url: "/message-center",
    },
    {
        title: "Reuben Ward",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-4.jpg",
        time: "08:00AM",
        url: "/message-center",
    },
    {
        title: "Evalyn Jenkins",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-5.jpg",
        time: "07:01AM",
        url: "/message-center",
    },
    {
        title: "Jarret Waelchi",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-1.jpg",
        time: "03:30PM",
        new: true,
        url: "/message-center",
    },
    {
        title: "Orval Casper",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-2.jpg",
        time: "11:59AM",
        online: true,
        url: "/message-center",
    },
    {
        title: "Michel Emard",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-3.jpg",
        time: "09:30AM",
        new: true,
        url: "/message-center",
    },
    {
        title: "Reuben Ward",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-4.jpg",
        time: "08:00AM",
        url: "/message-center",
    },
    {
        title: "Evalyn Jenkins",
        content: "When do you release the coded template",
        avatar: "/images/content/avatar-5.jpg",
        time: "07:01AM",
        url: "/message-center",
    },
];



const Messages = ({ className }) => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch()
    const messages = useSelector(selectNotifications)
    console.log(messages, " messages");
    useEffect(()=>{
        console.log(messages, " ===> messags");
    },[messages])

    return (
        <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
            <div
                className={cn(styles.messages, className, {
                    [styles.active]: visible,
                })}
            >
                <button
                    className={cn(styles.head, styles.active)}
                    onClick={() => setVisible(!visible)}
                >
                    {
                  messages?.data?.find((i) => !(i?.isRead)) ?
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19 12H15.8C14.1198 12 13.2798 12 12.638 12.327C12.0735 12.6146 11.6146 13.0735 11.327 13.638C11 14.2798 11 15.1198 11 16.8V24.2C11 25.8802 11 26.7202 11.327 27.362C11.6146 27.9265 12.0735 28.3854 12.638 28.673C13.2798 29 14.1198 29 15.8 29H23.2C24.8802 29 25.7202 29 26.362 28.673C26.9265 28.3854 27.3854 27.9265 27.673 27.362C28 26.7202 28 25.8802 28 24.2V21" stroke="#6C7275" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M28.1213 11.8787C29.2929 13.0503 29.2929 14.9497 28.1213 16.1213C26.9497 17.2929 25.0503 17.2929 23.8787 16.1213C22.7071 14.9497 22.7071 13.0503 23.8787 11.8787C25.0503 10.7071 26.9497 10.7071 28.1213 11.8787Z" fill="#D81C10" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  <path d="M11 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V13M20.1213 3.87868C21.2929 5.05025 21.2929 6.94975 20.1213 8.12132C18.9497 9.29289 17.0503 9.29289 15.8787 8.12132C14.7071 6.94975 14.7071 5.05025 15.8787 3.87868C17.0503 2.70711 18.9497 2.70711 20.1213 3.87868Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
                }
                </button>
                <div className={styles.body}>
                    <div className={styles.top} onClick={()=>{
                        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
                        console.log(sessions, " ===> sessions on message click");
                    }} >
                         <h3 >Notifications</h3>{/*//className={styles.title} */}
                    </div>
                    <div className={styles.list}>
                        {messages?.data?.filter(i=>(i?.name != 'SUPERUSER_REQUEST' && i?.name != 'SUPERUSER_APPROVED'))?.map((x, index) => (
                            <Item
                                className={cn(styles.item, className)}
                                item={x}
                                key={index}
                                onClose={() => setVisible(false)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default Messages;
