import React from "react";
import styles from "./VideoModal.module.sass";

const VideoModal = () => {
  return (
    <video className={styles.vedio} controls>
      <source
        src={
          "https://lokichatdev.blob.core.windows.net/videos/loki_chat.-_referral_program%20(2160p).mp4"
        }
        type="video/mp4"
      ></source>
    </video>
  );
};

export default VideoModal;
