import { detect } from 'detect-browser'
import { isEmpty } from 'lodash';

export const getUserIPFromIpify = async () => {
    const responseIP = await fetch("https://api.ipify.org?format=json");
    const jsonIP = await responseIP.json();
    return jsonIP?.ip;
}
export const getUserBrowserInfo = () => {
    const browser = detect();
    return {
        os: browser?.os,
        browserName: browser?.name,
        browserVersion: browser?.version
    }
}
export const getUserIP = async () => {

    try {
        const response = await fetch('https://geolocation-db.com/json/')
        console.log('response', response, );
        // // return "192.168.12.3"
        const result = await response.json();
        console.log('response===', result );

        if (isEmpty(result.IPv4) || result?.IPv4?.toLowerCase()?.includes('found')) {
          return await getUserIPFromIpify()
        } else {
          return result?.IPv4;
        }
    } catch (err) {
    console.log('respon err', err);
    return await getUserIPFromIpify()
    }

}