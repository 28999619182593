import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster, resolveValue } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { Provider } from 'react-redux';

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
// import { initializei18n } from "./services/i18n";
import { I18nextProvider } from 'react-i18next';
import { initializei18n, i18n } from "./services/i18n";

initializei18n();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      <Toaster
        containerStyle={{
          bottom: 40,
          left: 20,
          right: 20,
        }}
        position="bottom-center"
        gutter={10}
        toastOptions={{ duration: 2000 }}
      >
        {(t) => (
          <div
            style={{
              opacity: t.visible ? 1 : 0,
              transform: t.visible ? "translatey(0)" : "translatey(0.75rem)",
              transition: "all .2s",
            }}
          >
            {resolveValue(t.message, t)}
          </div>
        )}
      </Toaster>
    
    </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);
