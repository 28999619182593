import { createSlice } from "@reduxjs/toolkit";
import { getMineNotifications, updateNotificationsList, readNotificationsList } from "../actions/notification.action"

export const initialState = {
  allNotifications: {
    data: [],
    loading: false,
    error: null,
  },
  updateUserNotificationList: {
    data: null,
    loading: false,
    error: null,
  }
}

export const notificationsSlice = createSlice({
  name: "notificationStore",
  initialState,
  reducers: {
    resetUpdateUserNotificationData(state, action) {
      state.updateUserNotificationList.loading = false;
      state.updateUserNotificationList.data = null;
      state.updateUserNotificationList.error = null;
    }
  },
  extraReducers: {
    [getMineNotifications.pending.type]: (state, action) => {
      state.allNotifications.loading = true;
      state.allNotifications.error = null;
    },
    [getMineNotifications.fulfilled.type]: (state, action) => {
      state.allNotifications.loading = false;
      state.allNotifications.data = action.payload?.data?.notifications;
      state.allNotifications.error = null;
    },
    [getMineNotifications.rejected.type]: (state, action) => {
      state.allNotifications.loading = false;
      state.allNotifications.error = action.error?.message;
    },
    [updateNotificationsList.pending.type]: (state, action) => {
      state.updateUserNotificationList.loading = true;
      state.updateUserNotificationList.data = null;
      state.updateUserNotificationList.error = null;
    },
    [updateNotificationsList.fulfilled.type]: (state, action) => {
      state.updateUserNotificationList.loading = false;
      state.updateUserNotificationList.data = action.payload.data;
      state.updateUserNotificationList.error = null;
    },
    [updateNotificationsList.rejected.type]: (state, action) => {
      state.updateUserNotificationList.loading = false;
      state.updateUserNotificationList.data = null;
      state.updateUserNotificationList.error = action.error?.message;
    },
  },
});

export const selectNotifications = (state) => state.notificationStore.allNotifications

export const { resetUpdateUserNotificationData } = notificationsSlice.actions;

export default notificationsSlice.reducer;
