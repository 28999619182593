import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Page from "./components/Page";
import Home from "./screens/Home";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import PageList from "./screens/PageList";
import Campaigns from "./screens/Compaigns";
import CompaignsName from "./screens/CompaignsName";
import Referral from "./screens/Referral";
import ReferralsList from "./screens/ReferralsList";
import Income from "./screens/Income";
import { useNavigate, useParams,  } from "react-router-dom";
import { encryptAndSave, loadDecryptedValue } from "./utils/encryption";
import { USER_LOCAL, USER_ROLES } from "./constants/user";
import "./styles/app.sass";
import config from "./config";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { getMineNotifications } from "./store/actions/notification.action";
import notificationSound from './not.wav'

import { clarity } from "react-microsoft-clarity";
import { initializei18n } from "./services/i18n";
import { Suspense } from 'react';
import SelectedLanguage from "./components/SelectLanguage";
import Loader from "./components/Loader";

const socket = io(config.baseUrl, {});
function App() {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(()=>{

    const urlSearchParams = new URLSearchParams(window.location.search);
    
    const token = urlSearchParams.get('token');
    const refreshToken = urlSearchParams.get('refreshToken');
    const role = urlSearchParams.get('role');
    const userId = urlSearchParams.get('userId');
    const isDark = urlSearchParams.get('isDark');

    if(token && role && userId) {
      const userData = {};
      userData.token = token;
      userData.userId = userId;
      userData.role = role;
      userData.refreshToken = refreshToken;
      localStorage.setItem('darkMode', isDark)
      encryptAndSave(USER_LOCAL.LOCAL, JSON.stringify(userData));
      navigate('/');
    }
    const userSessions = loadDecryptedValue(USER_LOCAL.LOCAL);

    console.log('userSessions', userSessions);

    // if(!userSessions || !userSessions.token || userSessions.role !== USER_ROLES.SUERP_USER) {
    //   navigate('/sign-in');
    // }

  },[])

  const dispatch = useDispatch()

  useEffect(() => {
    clarity.init('jh3g1r0axm');
    const i18nInstance = initializei18n();
  }, []);


  

  // const [isI18nInitialized, setIsI18nInitialized] = useState(false);
  // const initialize = async () => {
  //   await initializei18n();
  //   setIsI18nInitialized(true);
  // }
  // useEffect(() => {
  //   clarity.init('jh3g1r0axm');
  //   initialize();
  // }, []);

  useEffect(()=>{
    dispatch(getMineNotifications())
  },[])

  useEffect(() => {
    const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
    console.log("sessions", sessions);
    const session = sessions?.token;
    if (session) {
      socket.onAny((eventName, args) => {
        // alert(JSON.stringify({ eventName, args }))
        if (args?.isNotification == true) {
          // console.log(args?.isNotification,args?.userIds, user?.data?.id, user, sessions, " notifications");
          
          let isThisUser = args?.userIds?.find((i) => (i?.toString() == sessions?.userId?.toString()))
          if (isThisUser) {
            dispatch(getMineNotifications())
            let aud = new Audio(notificationSound)
            aud.play()
          }
        }
      })
    }
    // socket.on(`${SOCKET_EVENT_TYPES.SEND_RECEIVE_NOTIFICATION}`, (payload) =>
    //   console.log("socket payload", payload)
    // );
    // socket.on(`${SOCKET_EVENT_TYPES.SUMMARY_STATUS}`, (payload) => {
    //   console.log(payload, ' =====> Notification Socket Payload.');
    // })
  }, []);

  return (

    <Suspense fallback={<div className="" style={{width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
      <Loader width={"300"} height={"300"} />
      <h3>Loading...</h3>
    </div>}>

    <Routes>
      <Route path="/">
        <Route index element={<Page title=""><Home /></Page>}/>
        <Route path="/compaigns" element={<Page title=""><Campaigns /></Page>}/>
        {/* <Route path="/compaigns-name" element={<Page title=""><CompaignsName /></Page>}/> */}
        <Route path="/compaigns/:id" element={<Page title=""><CompaignsName /></Page>} />

        <Route path="referrals/overview" element={<Page title="Referral"><Referral /></Page>} />
        <Route path="referrals/referrals-list" element={<Page title="Referral List"><ReferralsList /></Page>} />
        <Route path="income" element={<Page title="Income"><Income /></Page>}/>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="pagelist" element={<PageList />} />
        <Route path="switch" element={<SelectedLanguage />} />

      </Route>
    </Routes>
    
    </Suspense>
  );
}
export default App;
