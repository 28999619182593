function TotalCompaigns(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <rect width="37" height="37" rx="11" fill="#1A1D1F"/>
        <path d="M19.98 11.0583C19.8741 11.011 19.7558 10.9919 19.6387 11.0031C19.5216 11.0143 19.4102 11.0554 19.3173 11.1218L13.8206 15.0385H10.2593C9.92528 15.0385 9.60499 15.1601 9.36883 15.3765C9.13267 15.5929 9 15.8864 9 16.1924V20.8079C9 21.114 9.13267 21.4075 9.36883 21.6239C9.60499 21.8403 9.92528 21.9618 10.2593 21.9618H13.8206L19.3173 25.8786C19.4103 25.9448 19.5217 25.9858 19.6389 25.9969C19.7561 26.008 19.8743 25.9888 19.9802 25.9414C20.086 25.894 20.1752 25.8203 20.2377 25.7288C20.3001 25.6372 20.3332 25.5314 20.3333 25.4235V11.5768C20.3333 11.4687 20.3002 11.3628 20.2378 11.2712C20.1753 11.1795 20.0859 11.1057 19.98 11.0583ZM19.0741 24.2436L14.4235 20.9298C14.3132 20.8506 14.1771 20.8077 14.037 20.8079H10.2593V16.1924H14.037C14.1771 16.1926 14.3132 16.1497 14.4235 16.0705L19.0741 12.7567V24.2436ZM23.4815 16.7693V20.231C23.4815 20.384 23.4151 20.5308 23.2971 20.639C23.179 20.7471 23.0188 20.8079 22.8519 20.8079C22.6849 20.8079 22.5247 20.7471 22.4066 20.639C22.2886 20.5308 22.2222 20.384 22.2222 20.231V16.7693C22.2222 16.6163 22.2886 16.4696 22.4066 16.3614C22.5247 16.2532 22.6849 16.1924 22.8519 16.1924C23.0188 16.1924 23.179 16.2532 23.2971 16.3614C23.4151 16.4696 23.4815 16.6163 23.4815 16.7693ZM26 15.6154V21.3849C26 21.5379 25.9337 21.6846 25.8156 21.7928C25.6975 21.901 25.5374 21.9618 25.3704 21.9618C25.2034 21.9618 25.0432 21.901 24.9252 21.7928C24.8071 21.6846 24.7407 21.5379 24.7407 21.3849V15.6154C24.7407 15.4624 24.8071 15.3157 24.9252 15.2075C25.0432 15.0993 25.2034 15.0385 25.3704 15.0385C25.5374 15.0385 25.6975 15.0993 25.8156 15.2075C25.9337 15.3157 26 15.4624 26 15.6154Z" fill="white"/>
        </svg>
    )
}

function TotalReferrals(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <g clip-path="url(#clip0_2737_30598)">
          <rect width="37" height="37" rx="11" fill="#1A1D1F"/>
          <path d="M14.6665 21C17.4279 21 19.6665 18.9853 19.6665 16.5C19.6665 14.0147 17.4279 12 14.6665 12C11.9051 12 9.6665 14.0147 9.6665 16.5C9.6665 18.9853 11.9051 21 14.6665 21Z" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.2664 12.1792C21.9641 11.9939 22.6958 11.9517 23.4123 12.0554C24.1288 12.1591 24.8133 12.4063 25.4199 12.7803C26.0265 13.1543 26.5409 13.6465 26.9287 14.2237C27.3164 14.8008 27.5684 15.4496 27.6676 16.1263C27.7669 16.803 27.7112 17.4919 27.5041 18.1465C27.2971 18.8012 26.9436 19.4064 26.4675 19.9215C25.9913 20.4366 25.4036 20.8495 24.7438 21.1325C24.0841 21.4155 23.3677 21.562 22.6428 21.562C24.0204 21.5611 25.378 21.873 26.6007 22.4714C27.8233 23.0697 28.8749 23.9369 29.6665 24.9995M7.6665 25C8.4587 23.938 9.51042 23.0712 10.7329 22.4729C11.9553 21.8745 13.3126 21.5621 14.6901 21.562C16.0676 21.562 17.4248 21.8743 18.6473 22.4726C19.8698 23.0709 20.9216 23.9376 21.7139 24.9995" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_2737_30598">
            <rect width="37" height="37" rx="11" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    )
}
function TotalCommission(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
  <rect width="37" height="37" rx="11" fill="#1A1D1F"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3335 14.2142C14.5027 14.3634 13.7069 14.6976 13.0001 15.1994C12.0134 15.8999 11.2443 16.8955 10.7902 18.0604C10.3361 19.2253 10.2173 20.5071 10.4488 21.7437C10.6803 22.9803 11.2517 24.1162 12.0909 25.0078C12.93 25.8994 13.9991 26.5065 15.163 26.7525C16.3268 26.9985 17.5332 26.8722 18.6296 26.3897C19.726 25.9072 20.663 25.0901 21.3223 24.0418C21.7946 23.2908 22.1091 22.4452 22.2496 21.5625H16.3335C16.0683 21.5625 15.8139 21.4506 15.6264 21.2513C15.4389 21.052 15.3335 20.7818 15.3335 20.5V14.2142ZM11.8889 13.4325C13.2045 12.4985 14.7512 12 16.3335 12C16.8858 12 17.3335 12.4757 17.3335 13.0625V19.4375H23.3335C23.8858 19.4375 24.3335 19.9132 24.3335 20.5C24.3335 22.1811 23.8643 23.8245 22.9853 25.2223C22.1062 26.6202 20.8568 27.7096 19.395 28.353C17.9332 28.9963 16.3246 29.1646 14.7728 28.8367C13.2209 28.5087 11.7955 27.6992 10.6766 26.5104C9.55782 25.3217 8.7959 23.8071 8.48722 22.1583C8.17854 20.5094 8.33696 18.8004 8.94246 17.2472C9.54796 15.694 10.5733 14.3665 11.8889 13.4325Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6589 8.29289C19.8673 8.10536 20.1499 8 20.4446 8C22.8021 8 25.063 8.84285 26.73 10.3431C28.397 11.8434 29.3335 13.8783 29.3335 16C29.3335 16.5523 28.836 17 28.2224 17L20.4446 17C20.1499 17 19.8673 16.8946 19.6589 16.7071C19.4506 16.5196 19.3335 16.2652 19.3335 16V9C19.3335 8.73478 19.4506 8.48043 19.6589 8.29289ZM21.5557 10.0839V15L27.0181 15C26.7889 13.7797 26.1439 12.644 25.1587 11.7574C24.1734 10.8707 22.9116 10.2902 21.5557 10.0839Z" fill="white"/>
</svg>
    )
}
function SmallChart1(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="42" viewBox="0 0 85 42" fill="none">
        <path d="M2.3335 40C2.3335 40 11.9552 22.5 22.3335 22.5C32.3335 22.5 32.3335 29 42.3335 29C52.7118 29 51.9552 11.5 62.3335 11.5C71.8335 11.5 82.3335 21 82.3335 21" stroke="#83BF6E" stroke-width="4" stroke-linecap="round"/>
      </svg>
    )
}
function SmallChart2(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="84" height="40" viewBox="0 0 84 40" fill="none">
        <path d="M82 29C82 29 72.3783 5.5 62 5.5C52 5.5 52 16.5 42 16.5C31.6217 16.5 32.3783 34 22 34C12.5 34 2 24.5 2 24.5" stroke="#2A85FF" stroke-width="4" stroke-linecap="round"/>
      </svg>
    )
}
function SmallChart3(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="84" height="40" viewBox="0 0 84 40" fill="none">
        <path d="M82 29C82 29 72.3783 5.5 62 5.5C52 5.5 52 16.5 42 16.5C31.6217 16.5 32.3783 34 22 34C12.5 34 2 24.5 2 24.5" stroke="#8E59FF" stroke-width="4" stroke-linecap="round"/>
      </svg>
    )
}
function Edit(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1803 2.01774C10.9582 1.91828 11.7451 2.23636 12.5447 2.98872L12.5457 2.98959C13.3479 3.74832 13.7132 4.51798 13.6584 5.3019C13.6054 6.0594 13.1663 6.70054 12.6655 7.22958M12.6655 7.22958L7.19493 13.02C7.0407 13.188 6.83304 13.3302 6.63618 13.4355C6.43678 13.5421 6.20614 13.6333 5.98961 13.6718L5.98628 13.6724L3.84038 14.0389C3.32001 14.1287 2.82101 13.9986 2.46554 13.6615C2.11057 13.3249 1.95371 12.834 2.01182 12.311L2.01199 12.3095L2.25973 10.1401C2.2885 9.92431 2.36865 9.69079 2.46312 9.48821C2.55725 9.28636 2.68568 9.07233 2.83767 8.91053L2.83865 8.9095L8.31198 3.11616C8.81301 2.58691 9.4287 2.11386 10.1803 2.01774M9.03854 3.80327L3.56652 9.59521C3.51187 9.65352 3.43681 9.76635 3.36941 9.91086C3.30324 10.0528 3.26349 10.184 3.25158 10.2684L3.00571 12.4214L3.00563 12.4221C2.97729 12.6787 3.05704 12.8443 3.15366 12.9359C3.24985 13.0271 3.41752 13.0971 3.67048 13.0535L3.67125 13.0533L5.81599 12.687C5.8995 12.6719 6.02818 12.6266 6.16468 12.5536C6.30307 12.4796 6.40718 12.3998 6.45929 12.3427L6.46526 12.3361L11.9387 6.54283C12.3844 6.07194 12.6321 5.64283 12.6608 5.23217C12.6877 4.84786 12.5297 4.35103 11.8591 3.71667C11.1922 3.08934 10.6893 2.96081 10.3072 3.00967C9.89891 3.06187 9.48408 3.33269 9.03854 3.80327Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.68513 3.93216C7.95798 3.88965 8.21363 4.07638 8.25614 4.34923C8.50756 5.96298 9.81724 7.19805 11.4455 7.36205C11.7203 7.38973 11.9206 7.63489 11.8929 7.90964C11.8652 8.18439 11.6201 8.38469 11.3453 8.35702C9.26696 8.14769 7.58997 6.56943 7.26806 4.50317C7.22555 4.23032 7.41228 3.97467 7.68513 3.93216Z" fill="white"/>
    </svg>
    )
}
function Delete(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
  <path d="M9.66667 4.00065V3.46732C9.66667 2.72058 9.66667 2.34721 9.52134 2.062C9.39351 1.81111 9.18954 1.60714 8.93865 1.47931C8.65344 1.33398 8.28007 1.33398 7.53333 1.33398H6.46667C5.71993 1.33398 5.34656 1.33398 5.06135 1.47931C4.81046 1.60714 4.60649 1.81111 4.47866 2.062C4.33333 2.34721 4.33333 2.72058 4.33333 3.46732V4.00065M5.66667 7.66732V11.0007M8.33333 7.66732V11.0007M1 4.00065H13M11.6667 4.00065V11.4673C11.6667 12.5874 11.6667 13.1475 11.4487 13.5753C11.2569 13.9516 10.951 14.2576 10.5746 14.4493C10.1468 14.6673 9.58677 14.6673 8.46667 14.6673H5.53333C4.41323 14.6673 3.85318 14.6673 3.42535 14.4493C3.04903 14.2576 2.74307 13.9516 2.55132 13.5753C2.33333 13.1475 2.33333 12.5874 2.33333 11.4673V4.00065" stroke="#FEFEFE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}
function Copy(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="currentColor" {...props}>
    <g clip-path="url(#clip0_2737_30018)">
      <path d="M14.2019 1.16992H3.65523C2.68406 1.16992 1.89746 1.95652 1.89746 2.92769V15.2321H3.65523V2.92769H14.2019V1.16992ZM16.8385 4.68547H7.17078C6.19961 4.68547 5.41301 5.47207 5.41301 6.44324V18.7476C5.41301 19.7188 6.19961 20.5054 7.17078 20.5054H16.8385C17.8097 20.5054 18.5963 19.7188 18.5963 18.7476V6.44324C18.5963 5.47207 17.8097 4.68547 16.8385 4.68547ZM16.8385 18.7476H7.17078V6.44324H16.8385V18.7476Z" fill="currentColor" fill-opacity=""/>
    </g>
    <defs>
      <clipPath id="clip0_2737_30018">
        <rect width="21.0933" height="21.0933" fill="currentColor" transform="translate(0.146484 0.289062)"/>
      </clipPath>
    </defs>
  </svg>
  )
}
function Twitter(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M29.3327 7.73361C28.3305 8.16841 27.2706 8.45583 26.186 8.58695C27.3302 7.90337 28.1877 6.82799 28.5993 5.56028C27.5241 6.20036 26.347 6.65134 25.1193 6.89361C24.2987 6.00371 23.206 5.41134 22.0124 5.20941C20.8189 5.00749 19.5921 5.20742 18.5244 5.77787C17.4567 6.34831 16.6086 7.25699 16.113 8.36139C15.6174 9.4658 15.5024 10.7035 15.786 11.8803C13.6119 11.7703 11.4853 11.2042 9.54422 10.2188C7.60317 9.23331 5.89114 7.85054 4.51935 6.16028C4.0382 7.0005 3.78537 7.95204 3.78602 8.92028C3.78431 9.81946 4.00497 10.7051 4.42836 11.4984C4.85174 12.2916 5.46471 12.9679 6.21268 13.4669C5.34332 13.4433 4.49253 13.21 3.73268 12.7869V12.8536C3.7392 14.1135 4.18067 15.3324 4.98243 16.3042C5.7842 17.2761 6.89702 17.9411 8.13268 18.1869C7.65702 18.3317 7.16318 18.408 6.66602 18.4136C6.32187 18.4096 5.97858 18.3784 5.63935 18.3203C5.99123 19.404 6.67218 20.3511 7.58745 21.0298C8.50272 21.7085 9.60678 22.085 10.746 22.1069C8.82229 23.6206 6.44719 24.4468 3.99935 24.4536C3.55366 24.4551 3.10833 24.4284 2.66602 24.3736C5.16525 25.9873 8.07777 26.8439 11.0527 26.8403C13.1056 26.8616 15.1422 26.4736 17.0435 25.6991C18.9448 24.9245 20.6727 23.7788 22.1263 22.3289C23.5799 20.8791 24.73 19.1541 25.5094 17.2548C26.2889 15.3555 26.6821 13.3199 26.666 11.2669C26.666 11.0403 26.666 10.8003 26.666 10.5603C27.7123 9.78003 28.6146 8.82351 29.3327 7.73361Z" fill="#0084FF"/>
  </svg>
  )
}
function Facebook(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M20.1592 7.09468H22.6658V2.85468C21.4522 2.72847 20.2327 2.66616 19.0125 2.66801C15.3858 2.66801 12.9058 4.88134 12.9058 8.93468V12.428H8.8125V17.1747H12.9058V29.3347H17.8125V17.1747H21.8925L22.5058 12.428H17.8125V9.40134C17.8125 8.00134 18.1858 7.09468 20.1592 7.09468Z" fill="#0084FF"/>
</svg>
  )
}
function TotalEarning(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.4889 5.05344C9.37598 4.52647 8.62402 4.52645 8.5111 5.05344L7.31658 10.6279C7.02017 12.0111 5.79778 12.9993 4.38317 12.9993H3C2.44772 12.9993 2 12.5516 2 11.9993C2 11.447 2.44772 10.9993 3 10.9993H4.38317C4.85471 10.9993 5.26217 10.6699 5.36097 10.2088L6.55549 4.63438C7.12011 1.99949 10.8799 1.99947 11.4445 4.63438L14.5111 18.9451C14.624 19.4721 15.376 19.4721 15.4889 18.9451L16.6834 13.3707C16.9798 11.9875 18.2022 10.9993 19.6168 10.9993H21C21.5523 10.9993 22 11.447 22 11.9993C22 12.5516 21.5523 12.9993 21 12.9993H19.6168C19.1453 12.9993 18.7378 13.3287 18.639 13.7898L17.4445 19.3642C16.8799 21.9991 13.1201 21.9991 12.5555 19.3642L9.4889 5.05344Z" fill="white"/>
  </svg>
  )
}
function AvailableBalance(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8.08392C8.16919 8.22435 7.37341 8.53889 6.66658 9.01118C5.67989 9.67047 4.91085 10.6075 4.45673 11.7039C4.0026 12.8003 3.88378 14.0067 4.11529 15.1705C4.3468 16.3344 4.91825 17.4035 5.75736 18.2426C6.59648 19.0818 7.66558 19.6532 8.82946 19.8847C9.99335 20.1162 11.1997 19.9974 12.2961 19.5433C13.3925 19.0892 14.3295 18.3201 14.9888 17.3334C15.4611 16.6266 15.7757 15.8308 15.9161 15H10C9.73479 15 9.48043 14.8946 9.2929 14.7071C9.10536 14.5196 9 14.2652 9 14V8.08392ZM5.55544 7.34824C6.87104 6.46919 8.41775 6 10 6C10.5523 6 11 6.44772 11 7V13H17C17.5523 13 18 13.4477 18 14C18 15.5823 17.5308 17.129 16.6518 18.4446C15.7727 19.7602 14.5233 20.7855 13.0615 21.391C11.5997 21.9965 9.99113 22.155 8.43928 21.8463C6.88743 21.5376 5.46197 20.7757 4.34315 19.6569C3.22433 18.538 2.4624 17.1126 2.15372 15.5607C1.84504 14.0089 2.00347 12.4003 2.60897 10.9385C3.21447 9.47672 4.23985 8.22729 5.55544 7.34824Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 2.29289C13.4804 2.10536 13.7348 2 14 2C16.1217 2 18.1566 2.84285 19.6569 4.34314C21.1571 5.84344 22 7.87827 22 10C22 10.5523 21.5523 11 21 11L14 11C13.7348 11 13.4804 10.8946 13.2929 10.7071C13.1054 10.5196 13 10.2652 13 10V3C13 2.73478 13.1054 2.48043 13.2929 2.29289ZM15 4.08389V9L19.9161 9C19.7098 7.77969 19.1293 6.64405 18.2426 5.75736C17.3559 4.87067 16.2203 4.29016 15 4.08389Z" fill="white"/>
    </svg>
  )
}
function TotalWithdrawn(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9996 2C9.79043 2 7.99957 3.79086 7.99957 6H6.6479C5.13493 6 3.85873 7.12661 3.67107 8.6279L2.42107 18.6279C2.19725 20.4185 3.59341 22 5.3979 22H18.6012C20.4057 22 21.8019 20.4185 21.578 18.6279L20.328 8.6279C20.1404 7.12661 18.8642 6 17.3512 6H15.9996C15.9996 3.79086 14.2087 2 11.9996 2ZM13.9996 8V9C13.9996 9.55228 14.4473 10 14.9996 10C15.5519 10 15.9996 9.55228 15.9996 9V8H17.3512C17.8555 8 18.2809 8.37554 18.3435 8.87597L19.5935 18.876C19.6681 19.4728 19.2027 20 18.6012 20H5.3979C4.79641 20 4.33102 19.4728 4.40563 18.876L5.65563 8.87597C5.71818 8.37554 6.14358 8 6.6479 8H7.99957V9C7.99957 9.55228 8.44729 10 8.99957 10C9.55186 10 9.99957 9.55228 9.99957 9V8H13.9996ZM13.9996 6C13.9996 4.89543 13.1041 4 11.9996 4C10.895 4 9.99957 4.89543 9.99957 6H13.9996Z" fill="white"/>
</svg>
  )
}
function Download(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12 7C12.5523 7 13 7.44772 13 8V13.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858V8C11 7.44772 11.4477 7 12 7Z" fill="currentColor"/>
  </svg>
  )
}
function LogOut(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M6.2341 2.38892C6.72964 2.40858 7.3347 2.47582 8.07124 2.55766L9.17177 2.67994C9.42373 2.70789 9.61105 2.72867 9.78005 2.76227C11.5265 3.10951 12.8331 4.56936 12.9854 6.34345C13.0001 6.51512 13.0001 6.70358 13 6.95708V7.40037C13 7.96042 13 8.24045 12.891 8.45436C12.7951 8.64252 12.6422 8.7955 12.454 8.89137C12.2401 9.00037 11.9601 9.00037 11.4 9.00037H9C7.34315 9.00037 6 10.3435 6 12.0004C6 13.6572 7.34315 15.0004 9 15.0004H11.4C11.9601 15.0004 12.2401 15.0004 12.454 15.1094C12.6422 15.2052 12.7951 15.3582 12.891 15.5464C13 15.7603 13 16.0403 13 16.6004V17.0436C13.0001 17.2971 13.0001 17.4856 12.9854 17.6572C12.8331 19.4313 11.5265 20.8912 9.78005 21.2384C9.61107 21.272 9.42373 21.2928 9.17184 21.3207L8.10931 21.4388L8.10734 21.439L8.07114 21.443C7.33464 21.5249 6.72961 21.5921 6.23408 21.6118C5.72274 21.6321 5.24546 21.6069 4.78089 21.4582C3.69725 21.1115 2.81256 20.3196 2.34824 19.2809C2.14918 18.8356 2.07147 18.364 2.03517 17.8536C1.99998 17.3589 1.99999 16.7501 2 16.009V7.99167C1.99999 7.25062 1.99998 6.64182 2.03517 6.14714C2.07147 5.63668 2.14918 5.1651 2.34824 4.71977C2.81256 3.68105 3.69725 2.88922 4.78089 2.54245C5.24547 2.39378 5.72275 2.36863 6.2341 2.38892Z" fill="currentColor"/>
    <path d="M15.2929 6.29326C15.6834 5.90274 16.3166 5.90274 16.7071 6.29326L21.7071 11.2933C22.0976 11.6838 22.0976 12.3169 21.7071 12.7075L16.7071 17.7075C16.3166 18.098 15.6834 18.098 15.2929 17.7075C14.9024 17.3169 14.9024 16.6838 15.2929 16.2933L18.5858 13.0004H9C8.44772 13.0004 8 12.5527 8 12.0004C8 11.4481 8.44772 11.0004 9 11.0004H18.5858L15.2929 7.70747C14.9024 7.31695 14.9024 6.68378 15.2929 6.29326Z" fill="currentColor"/>
  </svg>
  )
}
function LeftArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z" fill="#E8ECEF"/>
  </svg>
  )
}
function Notification(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M11 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V13M20.1213 3.87868C21.2929 5.05025 21.2929 6.94975 20.1213 8.12132C18.9497 9.29289 17.0503 9.29289 15.8787 8.12132C14.7071 6.94975 14.7071 5.05025 15.8787 3.87868C17.0503 2.70711 18.9497 2.70711 20.1213 3.87868Z" stroke="#6C7275" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}



export {TotalCompaigns,TotalReferrals,TotalCommission,SmallChart1,SmallChart2,SmallChart3,Edit,Delete,Copy,Twitter,Facebook,TotalEarning,AvailableBalance,TotalWithdrawn,Download,LogOut,LeftArrow,Notification}