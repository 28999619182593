import { createSlice } from "@reduxjs/toolkit";
import { getUserById, loginUser } from "../actions/auth.actions";

export const initialState = {
  user: {
    data: null,
    loading: false,
    error: null,
  },
  localUser:{},
  access_token: null,
  session: null,
};

export const authSlice = createSlice({
  name: "authStore",
  initialState,
  reducers: {
    resetAllUserData(state, action) {
      return initialState;
    },
  },
  extraReducers: {
    [loginUser.pending.type]: (state, action) => {
      state.user.data = null;
      state.user.error = null;
      state.user.loading = true;
    },
    [loginUser.fulfilled.type]: (state, action) => {
      state.user.data = action.payload.data;
    },
    [loginUser.rejected.type]: (state, action) => {
      state.user.data = null;
      state.user.loading = false;
      state.user.error = action.error?.message;
    },
    [getUserById.pending.type]: (state, action) => {
      state.localUser = null;
    },
    [getUserById.fulfilled.type]: (state, action) => {
      console.log(action.payload, " ===> payload action ");
      state.localUser = action.payload;
    },
    [getUserById.rejected.type]: (state, action) => {
      state.localUser = null;
    },
  },
});

export const { resetAllUserData } = authSlice.actions;

export default authSlice.reducer;
