import React, { useEffect, useState } from "react";
import styles from "./Income.module.sass";
import Overview from "./Overview";
import IncomeSales from "./IncomeSales";
import Table from "./Table";
import TransactionTable from "./TransactionTable";
import axios from "axios";
import config from "../../config";
import toast from "react-hot-toast";
import Image from "../../components/Image";
import { useDispatch } from "react-redux";
import { getIncomeData } from "../../store/actions/superuser.action";
import Notify from "../../components/Notify";
import { useTranslation } from "react-i18next";
import campaignService from "../../services/compaign.service";
import { loadDecryptedValue } from "../../utils/encryption";

import { USER_LOCAL } from "../../constants/user";

const getStripeLink = async () => {
  try {
    const { data } = await axios.get(
      `${config.baseApiUrl}/stripe/getAccountSetupLink`
    );
    console.log(data, " ===> data");
    window.open(data?.url, "_blank");
  } catch (error) {
    console.log(error);
  }
};

const getStripeConnectLoginLink = async () => {
  try {
    const { data } = await axios.get(
      `${config.baseApiUrl}/stripe/getConnectAccountLoginLink`
    );
    console.log(data, " ===> data to Login Stripe");
    window.open(data?.url, "_blank");
  } catch (error) {
    console.log(error);
  }
};

const Income = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const [pendingTransactions, setpendingTransactions] = useState([]);
  const [transactionHistory, settransactionHistory] = useState([]);
  const [hisLoading, sethisLoading] = useState(false);
  const [pendingLoading, setpendingLoading] = useState(false);
  const [transLoading, settransLoading] = useState(false);
  const [accountDetails, setaccountDetails] = useState("");
  const [record, setRecord] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const [commission, setCommission] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1 });

  function analyzeCommissions(data) {
    let count = 0; // Initialize count of entries with commission > 0
    let totalCommission = 0; // Initialize total sum of commissions

    // Loop through each record in the records array
    data.records.forEach((record) => {
      if (record.commission > 0) {
        count++; // Increment count if commission > 0
        totalCommission += record.commission; // Add commission to total
      }
    });

    return {
      count, // Number of entries with commission > 0
      totalCommission, // Sum of all commissions
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      //   setLoading(true);
      try {
        const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
        const response = await campaignService.referralList({
          creator: sessions.userId,
          search: "",
          filter: t("all-time"),
          page: paginationInfo.currentPage,
        });
        if (paginationInfo.currentPage === 1) {
          setRecord(response?.data?.data.records);
        } else {
          setRecord((prevRecord) => [
            ...prevRecord,
            ...response?.data?.data.records,
          ]);
        }
        let commission = analyzeCommissions(response?.data?.data);
        setCommission(commission);
        setTotalCount(response?.data?.data.totalRecords);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paginationInfo]);

  const getStripeAccountDetails = async () => {
    try {
      const { data } = await axios.get(
        `${config.baseApiUrl}/stripe/getConnectAccountDetails`
      );
      console.log(data, " ===> getStripeAccountDetails data");
      setaccountDetails(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStripeAccountDetails();
  }, []);

  const getPendingTransactions = async () => {
    try {
      setpendingLoading(true);
      const { data } = await axios.get(
        `${config.baseApiUrl}/stripe/getPendingTransactions`
      );
      console.log(data, " ===> Pending Data");
      setpendingTransactions(data);
      setpendingLoading(false);
    } catch (error) {
      setpendingLoading(false);
      console.log(error);
    }
  };

  const getTransactionHistory = async () => {
    try {
      sethisLoading(true);
      const { data } = await axios.get(
        `${config.baseApiUrl}/stripe/getTransactionHistory`
      );
      console.log(data, " ===> History Data");
      settransactionHistory(data);
      sethisLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? error?.message ?? "Unknown Error"
      );
      sethisLoading(false);
      console.log(error);
    }
  };

  const transferToConnectAccount = async ({ amount, againstUser, transId }) => {
    try {
      if (!(Number(amount) > 0) || !againstUser) {
        toast.error("Invalid Amount or user.");
        return;
      }
      settransLoading(true);
      const { data } = await axios.post(
        `${config.baseApiUrl}/stripe/transferToConnectAccount`,
        { amount, againstUser, transId }
      );
      console.log(data, " ===> History Data");
      settransLoading(false);
      window.location.reload();
    } catch (error) {
      toast(
        (t) => (
          <Notify>
            <div className="mr-6 ml-3 h6">
              {error?.response?.data?.message ??
                error?.message ??
                "Unknown Error"}
            </div>
          </Notify>
        ),
        {
          duration: 3600,
        }
      );
      // toast.error(error?.response?.data?.message ?? error?.message ?? 'Unknown Error')
      settransLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getPendingTransactions();
    getTransactionHistory();
    dispatch(getIncomeData({}));
  }, []);

  return (
    <div className={styles.section}>
      <div className={styles.stripeWrapper}>
        <div className={styles.stripInside}>
          <Image
            className={styles.stripeBg}
            src="/images/stripeLogo.svg"
            srcDark="/images/stripeLogo.svg"
            alt="Loki"
          />
          <div>
            <h5 className={styles.stripeHeading}>
              {accountDetails?.capabilities?.transfers == "active"
                ? t("loginStripe_account")
                : t("connect_account")}
            </h5>
            <p className={styles.stripeDetail}>
              {accountDetails?.capabilities?.transfers == "active"
                ? t("loginStripe_withLoki")
                : t("connectStripe_getPaid")}
            </p>
          </div>
        </div>
        <button
          className={styles.stripConnect}
          onClick={() => {
            accountDetails?.capabilities?.transfers == "active"
              ? getStripeConnectLoginLink()
              : getStripeLink();
          }}
        >
          {accountDetails?.capabilities?.transfers == "active"
            ? t("visit_Stripe")
            : t("Connect")}
        </button>
      </div>
      <Overview commission={commission} className={styles.card} />
      <IncomeSales />
      <TransactionTable
        transLoading={transLoading}
        data={pendingTransactions}
        transferToConnectAccount={transferToConnectAccount}
      />
      <Table data={transactionHistory} />
    </div>
  );
};

export default Income;
