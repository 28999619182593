import React, { useState } from 'react';
import styles from './CreateCompaignsModal.module.sass';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { generateComapign, updateCompaignById } from '../../../src/store/actions/compaign.actions';
import Notify from '../Notify';
import { toast } from 'react-hot-toast';
import { selectTotalData } from '../../store/slices/SuperuserSlice';
import { getMineNotifications } from '../../store/actions/notification.action';
import { getDashboardOverview } from '../../store/actions/superuser.action';
import { useTranslation } from 'react-i18next';

const CreateCompaginsModal = ({ setVisibleModal, selectedItem, setApiCall }) => {

  const [compaignName, setCompaignName] = useState(selectedItem ? selectedItem.title : '');
  const [invitationCode, setInvitationCode] = useState(selectedItem ? selectedItem.invitationCode : '');
  const [description, setDescription] = useState(selectedItem ? selectedItem.description : '');
  const initialErrors = {
    name: '',
    code : ""
  };
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const { t: lt } = useTranslation('translation');
  const [errors, setErrors] = useState(initialErrors);
  const [remainingChars, setRemainingChars] = useState(100 - description?.length); // Total character limit

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required(lt('campaign_titleRequire')),
    // name: Yup.string()
    // .matches(/^[^\d]*$/, { message: "Invalid campagin name." })
    // .required(lt('campaign_titleRequire')),
    name: Yup.string()
      .matches(/.*[^\d].*/, { message: lt('Invalid campagin name.') })
      .required(lt('campaign_titleRequire')),
    code: Yup.string()
      .matches(/^[^\d]*$/, { message: lt('Invalid invitation code.') })
      .required(lt('Invitation code is required')),
  });


  const handleNameChange = (e) => {
    if (e.target.value?.length < 60) {
      setCompaignName(e.target.value);
      // Clear the error when the user starts typing in the name field
      setErrors({ ...errors, name: '' });
    }
  };

  const handleInvitationCodeChange = (e) => {
    if (e.target.value?.length < 60) {
      setInvitationCode(e.target.value);
      // Clear the error when the user starts typing in the name field
      setErrors({ ...errors, code: '' });
    }
  };

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    setDescription(text);
    const charsUsed = text.length;
    const charsRemaining = 100 - charsUsed;
    setRemainingChars(charsRemaining);

    if (text && charsRemaining < 0) {
      setErrors({ ...errors, description: lt('charLimit_exceed') });
    } else {
      setErrors({ ...errors, description: '' });
    }
  };

  const totalData = useSelector(selectTotalData)

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await validationSchema.validate(
        {
          name: compaignName,
          code: invitationCode,
          description: description,
        },
        {
          abortEarly: false,
        }
      );



      if (errors.description) {

        // Don't submit if there's an error in the description
        return;
      }

      const data = {
        name: compaignName,
        description: description,
        referralCode: invitationCode,
        id: selectedItem ? selectedItem._id : undefined,
      };
      let error = "";
      if (selectedItem) {
        await dispatch(updateCompaignById(data)).unwrap();
        await dispatch(getMineNotifications({ filter: totalData?.filter }))
        setVisibleModal(false);
        setLoading(false)
        toast((t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt('update_success')}</div>
          </Notify>
        ));
      } else {
        await dispatch(generateComapign(data)).unwrap().then(()=>{ 
        }).catch((err)=>{
          console.log(err);
          setErrors({...errors,code:err.message});
          error = err.message;
          return;
        });
        if(error){
          return;
        }

        await dispatch(getDashboardOverview({ filter: totalData?.filter }))
        toast((t) => (
          <Notify iconCheck>
            <div className="h6 sm:text-sm">{lt('create_success')}</div>
          </Notify>
        ));
        if (setApiCall) setApiCall(true);

        setVisibleModal(false)
        setLoading(false)
      }

      setVisibleModal(false);
    } catch (validationErrors) {
      const fieldErrors = {};
      validationErrors.inner.forEach((error) => {
        fieldErrors[error.path] = error.message;
      });
      setErrors(fieldErrors);
      setLoading(false)
    }
  };


  return (
    <div>
      <h2 className={styles.heading}>{selectedItem ? "Update" : "Create"} {lt('campaign')}</h2>
      <span className={styles.description}>{lt('create_campaignDesc')}</span>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label className={styles.label}>{lt('campaign_title')}</label>
          <input
            type="text"
            placeholder={lt('campaign_title')}
            className={styles.input}
            value={compaignName}
            onChange={handleNameChange}
          />
          <div className={styles.error}>{errors.name}</div>
          <label className={styles.label}>{lt('Invitation Code')}</label>
          <input
            type="text"
            placeholder={lt('Invitation Code')}
            className={styles.input}
            value={invitationCode}
            onChange={handleInvitationCodeChange}
          />
          <div className={styles.error}>{errors.code}</div>
          <label className={styles.label}>{lt('descAbout_campaign')}</label>
          <textarea
            placeholder={lt('about_yourTalent')}
            className={styles.textarea}
            value={description}
            onChange={handleDescriptionChange}
          />
          <div className={styles.error}>{errors.description}</div>
          <div className={styles.remainingChars}>{lt('characters_remaining')} {remainingChars}</div>
        </fieldset>
        <button type="submit" className={styles.btn}>{lt('submit')}</button>
      </form>
    </div>
  );
};

export default CreateCompaginsModal;
