import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import { useSelector } from 'react-redux'
import { USER_LOCAL } from '../../constants/user';
import { loadDecryptedValue } from '../../utils/encryption'
import axios from 'axios'
import config from "../../config";
import Modal from "../Modal";
import VideoModal from "../VideoModal";
import Stripe from'stripe'
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../Modal/ConfirmationModal";
import Notify from '../Notify';
import { toast } from 'react-hot-toast';

const getStripeLink = async() => {
    try {
        const {data} = await axios.get(`${config.baseApiUrl}/stripe/getAccountSetupLink`)
        console.log(data," ===> data");
        window.open(data?.url,'_blank')
    } catch (error) {
        console.log(error);
    }
}

const getStripeConnectLoginLink = async() => {
    try {
        const {data} = await axios.get(`${config.baseApiUrl}/stripe/getConnectAccountLoginLink`)
        console.log(data," ===> data to Login Stripe");
        window.open(data?.url,'_blank')
    } catch (error) {
        console.log(error);
    }
}


const Sidebar = ({ className, onClose,setVisible,visible }) => {
    const [accountDetails, setaccountDetails] = useState('')
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleDownloadModal, setVisibleDownloadModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
    const { t } = useTranslation('translation')
    // const [visible, setVisible] = useState(false);
    const { pathname } = useLocation();
    const user = useSelector(state=>state.authStore)


    const navigation = [
        {
            title: t('dashboard'),
            value : 0,
            icon: "dashboard",
            url: "/",
        },
        {
            title: t('campaigns'),
            value : 1,
            icon: "compaigns",
            url: "/compaigns",
        },
        {
            title: t('Referrals'),
            value : 2,
            slug: "customers",
            icon: "compaings",
            dropdown: [
                {
                    title: t('overview'),
                    url: "/referrals/overview",
                },
                {
                    title: t('referral_list'),
                    url: "/referrals/referrals-list",
                },
            ],
        },
        {
            title: t('income'),
            value : 3,
            slug: "income",
            icon: "pie-chart",
            url: "/income",
        },
        // {
        //     title: "Setup Stripe",
        //     slug: "stripeSetup",
        //     icon: "pie-chart",
        //     url: "/",
        //     type:'btn',
        //     onclick:()=>{
        //         getStripeLink()
        //     }
        // },
    ];

    const getStripeAccountDetails = async() => {
        try {
            const {data} = await axios.get(`${config.baseApiUrl}/stripe/getConnectAccountDetails`)
            console.log(data," ===> getStripeAccountDetails data");
            setaccountDetails(data)
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownloadBrandKit = async() => {

        try {
            const anchor = document.createElement('a');
            anchor.href = "https://storage.googleapis.com/lokichat-profile/Loki-Partners-Brand-Kit.zip";
            anchor.download = 'Loki-Partners-Brand-Kit.zip';
            document.body.appendChild(anchor);
            anchor.click(); 
            
            document.body.removeChild(anchor);
            setVisibleDownloadModal(false);
            setLoading(false);

            toast(() => (
            <Notify iconCheck>
              <div className="h6 sm:text-sm">{t("download_started")}</div>
            </Notify>
            ), {duration: 1500});
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast((t) => (
            <Notify iconError>
                <div className="h6 sm:text-sm">{error.message}</div>
            </Notify>
            ), {duration: 1500});
        }
      };

    useEffect(()=>{
        getStripeAccountDetails()
    },[])

    useEffect(()=>{
        console.log(sessions," ===> user");
    },[sessions])

    return (
        <>
            <div
                className={cn(styles.sidebar, className, {
                    [styles.active]: visible,
                })}
            >
                <div className={styles.sidebarDiv}>
                    <Link className={cn(styles.lokiLogo,className,{[styles.active]: visible,})} to="/" >
                    <Image
                        src="/images/dark-logo.svg"
                        srcDark="/images/dark-logo.svg"
                        alt="loki"
                    />
                </Link>
                {/* <button className={styles.close} onClick={onClose}>
                    <Icon name="close" size="24" />
                </button> */}

                <div className={styles.logo}  onClick={() => setVisible(!visible)}>
                    <Image
                        className={styles.pic}
                        src="/images/side-logo.svg"
                        srcDark="/images/side-logo.svg"
                        alt="Loki"
                    />
                </div>
                </div>
                
                <div className={styles.innerMain}>
                <div className={styles.menu}>
                    {navigation?.map((x, index) =>
                        x?.url ? (
                            <Link
                                className={cn(styles.item, {
                                    [styles.active]: pathname === x?.url,
                                    // [styles.active]: visible,
                                })}
                                to={x?.url}
                                key={index}
                                // onClick={x?.type == 'btn' ? (e)=>{
                                //     e.preventDefault();
                                //     e.stopPropagation();
                                //     x.onclick()
                                // } : onClose}
                            >
                                <span className={styles.svg}>
                                {
                                    x?.value === 0 && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.49409 7.58903C1.96731 8.06314 1.6665 8.73855 1.6665 9.44727V15.8339C1.6665 17.2146 2.78579 18.3339 4.1665 18.3339H15.8332C17.2139 18.3339 18.3332 17.2146 18.3332 15.8339V9.44727C18.3332 8.73855 18.0324 8.06314 17.5056 7.58903L11.6722 2.33903C10.7215 1.48334 9.27819 1.48334 8.32742 2.33903L2.49409 7.58903ZM9.1665 10.8339C8.24603 10.8339 7.49984 11.5801 7.49984 12.5005V15.8339C7.49984 16.2941 7.87293 16.6672 8.33317 16.6672H11.6665C12.1267 16.6672 12.4998 16.2941 12.4998 15.8339V12.5005C12.4998 11.5801 11.7536 10.8339 10.8332 10.8339H9.1665Z" fill="#3E90F0"/>
                                  </svg>
                                }
                                  {
                                    x?.value=== 1 && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13.98 4.06219C13.8741 4.01176 13.7558 3.99137 13.6387 4.00333C13.5216 4.0153 13.4102 4.05914 13.3173 4.12988L7.8206 8.30773H4.25926C3.92528 8.30773 3.60499 8.4374 3.36883 8.66822C3.13267 8.89905 3 9.21211 3 9.53854V14.4618C3 14.7882 3.13267 15.1013 3.36883 15.3321C3.60499 15.5629 3.92528 15.6926 4.25926 15.6926H7.8206L13.3173 19.8705C13.4103 19.9411 13.5217 19.9849 13.6389 19.9967C13.7561 20.0086 13.8743 19.9881 13.9802 19.9375C14.086 19.8869 14.1752 19.8083 14.2377 19.7107C14.3001 19.613 14.3332 19.5002 14.3333 19.3851V4.61528C14.3333 4.49999 14.3002 4.38702 14.2378 4.28923C14.1753 4.19144 14.0859 4.11277 13.98 4.06219ZM13.0741 18.1265L8.42347 14.5918C8.31321 14.5074 8.17712 14.4616 8.03704 14.4618H4.25926V9.53854H8.03704C8.17712 9.53876 8.31321 9.49298 8.42347 9.40854L13.0741 5.87379V18.1265ZM17.4815 10.1539V13.8464C17.4815 14.0096 17.4151 14.1661 17.2971 14.2815C17.179 14.397 17.0188 14.4618 16.8519 14.4618C16.6849 14.4618 16.5247 14.397 16.4066 14.2815C16.2886 14.1661 16.2222 14.0096 16.2222 13.8464V10.1539C16.2222 9.99073 16.2886 9.8342 16.4066 9.71879C16.5247 9.60338 16.6849 9.53854 16.8519 9.53854C17.0188 9.53854 17.179 9.60338 17.2971 9.71879C17.4151 9.8342 17.4815 9.99073 17.4815 10.1539ZM20 8.92313V15.0772C20 15.2404 19.9337 15.397 19.8156 15.5124C19.6975 15.6278 19.5374 15.6926 19.3704 15.6926C19.2034 15.6926 19.0432 15.6278 18.9252 15.5124C18.8071 15.397 18.7407 15.2404 18.7407 15.0772V8.92313C18.7407 8.75992 18.8071 8.60339 18.9252 8.48797C19.0432 8.37256 19.2034 8.30773 19.3704 8.30773C19.5374 8.30773 19.6975 8.37256 19.8156 8.48797C19.9337 8.60339 20 8.75992 20 8.92313Z" fill="#FF97E8"/>
                                  </svg>
                                }
                                  {
                                    x?.value === 3 && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 8.08392C8.16919 8.22435 7.37341 8.53889 6.66658 9.01118C5.67989 9.67047 4.91085 10.6075 4.45673 11.7039C4.0026 12.8003 3.88378 14.0067 4.11529 15.1705C4.3468 16.3344 4.91825 17.4035 5.75736 18.2426C6.59648 19.0818 7.66558 19.6532 8.82946 19.8847C9.99335 20.1162 11.1997 19.9974 12.2961 19.5433C13.3925 19.0892 14.3295 18.3201 14.9888 17.3334C15.4611 16.6266 15.7757 15.8308 15.9161 15H10C9.73479 15 9.48043 14.8946 9.2929 14.7071C9.10536 14.5196 9 14.2652 9 14V8.08392ZM5.55544 7.34824C6.87104 6.46919 8.41775 6 10 6C10.5523 6 11 6.44772 11 7V13H17C17.5523 13 18 13.4477 18 14C18 15.5823 17.5308 17.129 16.6518 18.4446C15.7727 19.7602 14.5233 20.7855 13.0615 21.391C11.5997 21.9965 9.99113 22.155 8.43928 21.8463C6.88743 21.5376 5.46197 20.7757 4.34315 19.6569C3.22433 18.538 2.4624 17.1126 2.15372 15.5607C1.84504 14.0089 2.00347 12.4003 2.60897 10.9385C3.21447 9.47672 4.23985 8.22729 5.55544 7.34824Z" fill="#FB692A"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2929 2.29289C13.4804 2.10536 13.7348 2 14 2C16.1217 2 18.1566 2.84285 19.6569 4.34314C21.1571 5.84344 22 7.87827 22 10C22 10.5523 21.5523 11 21 11L14 11C13.7348 11 13.4804 10.8946 13.2929 10.7071C13.1054 10.5196 13 10.2652 13 10V3C13 2.73478 13.1054 2.48043 13.2929 2.29289ZM15 4.08389V9L19.9161 9C19.7098 7.77969 19.1293 6.64405 18.2426 5.75736C17.3559 4.87067 16.2203 4.29016 15 4.08389Z" fill="#FB692A"/>
                                  </svg>
                                }
                                
                                </span>
                                <span className={cn(styles.title,className,{[styles.active]: visible,})}>{x?.title}</span>
                            </Link>
                        ) : (
                            <Dropdown
                                className={styles.dropdown}
                                visibleSidebar={visible}
                                setValue={setVisible}
                                key={index}
                                item={x}
                                
                                // onClose={onClose}
                            />
                        )
                    )}
                </div>
                {/* <button
                    className={styles.toggle}
                    onClick={() => setVisible(!visible)}
                >
                    <Icon name="arrow-right" size="24" />
                </button> */}
                <div className={styles.btnDownload}>
                    <button
                    style={{color: '#fff'}}
                    onClick={() => setVisibleDownloadModal(true)}
                    >
                    {t("download_brand_kit")}
                    <span className="ms-2">
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M3 12.003V12.75C3 13.3467 3.23705 13.919 3.65901 14.341C4.08097 14.7629 4.65326 15 5.25 15H12.75C13.3467 15 13.919 14.7629 14.341 14.341C14.7629 13.919 15 13.3467 15 12.75V12M9 3.375V11.625M9 11.625L11.625 9M9 11.625L6.375 9"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                    </button>
                </div>
                <div className={cn(styles.vedioDiv, className, {
                    [styles.active]: visible,})}>
                    <h6 className={styles.vedioTitle}>{t('video_guide')}</h6>
                    <p className={styles.vediodec}>{t('earnBy_affiliate')}</p>
            
                    <Image
                        className={styles.vedioImg}
                        src="/images/video.svg"
                        srcDark="/images/video.svg"
                        alt="Loki"
                        
                    />
                    <div className={styles.btnGuide}>
                    <button
                        style={{color: '#fff'}}
                        onClick={() => setVisibleModal(true)}
                    >
                    {t("watch_video_guide")}
                    <span className="ms-2">
                        <Image src={"/images/video_icon.svg"}  srcDark={"/images/video_icon.svg"} fill alt="video-icon" />
                    </span>
                    </button>
                </div>
                </div>


                <div className={cn(styles.stripeWrapper, className, {
                    [styles.active]: visible,})}>
                    <div className={styles.stripe}>
                    <Image
                        src="/images/stripe-logo.svg"
                        srcDark="/images/stripe-logo.svg"
                        alt="Loki"
                    />
                    <p className={styles.stripeDetail}>{t('stripe_loki')}</p>
                    <button onClick={()=>{accountDetails?.capabilities?.transfers == 'active' ? getStripeConnectLoginLink() : getStripeLink()}} className={styles.btnConnect}>{accountDetails?.capabilities?.transfers == 'active' ? t('login_Stripe') : t('Connect')}</button>
                    </div>
                </div>
               
            </div>
            <div className={styles.foot}>
                    {/* <button
                        className={styles.link}
                        onClick={() => setVisibleHelp(true)}
                    >
                        <Icon name="help" size="24" />
                        Help & getting started
                        <div className={styles.counter}>8</div>
                    </button> */}
                <button  className={cn(styles.btn, className, { [styles.active]: visible, })}
                onClick={()=>{window.location.replace(`${config.frontEndUrl}/chat`)}}
                >{t('switch_loki')}</button>
                <span  onClick={()=>{window.location.replace(`${config.frontEndUrl}/chat`)}} className={cn(styles.switch, className, { [styles.active]: visible, })}>
                <MdOutlineSwitchAccessShortcut />
                </span>
                    <Theme className={styles.theme} visibleSidebar={visible} visible={visible} />
                </div>
            <Help
                visible={visibleHelp}
                setVisible={setVisibleHelp}
                onClose={onClose}
            />
            <div
                className={cn(styles.overlay, { [styles.active]: visible })}
                onClick={() => setVisible(false)}
            ></div>
            </div>

            <Modal
            outerClassName={styles.outer}
            visible={visibleModal}
            onClose={() => setVisibleModal(false)}
           >
            <VideoModal />
          </Modal>
          <Modal
                outerClassName={styles.downloadModalOuter}
                visible={visibleDownloadModal}
                onClose={() => setVisibleDownloadModal(false)}
            >
                <ConfirmationModal disabled={false} onClose={() => setVisibleDownloadModal(false)} onSave={handleDownloadBrandKit} />
            </Modal>
        </>
    );
};

export default Sidebar;
