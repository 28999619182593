import React from "react";
import styles from "./Notify.module.sass";
import cn from "classnames";
import { FiCheck } from 'react-icons/fi';
import { Delete } from "../Icons";

const Notify = ({
    className,
    iconCheck,
    iconDelete,
    children,
}) => (
    <div className={cn(styles.Notify,className)}>
        {iconCheck && (
            <span className={styles.check}><FiCheck/></span>
        )}
        {iconDelete && (
            <span className={styles.delete}><Delete/></span>
        )}
        {children}
    </div>
);

export default Notify;
