import React from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";
import { Rings } from 'react-loader-spinner'

const Loader = ({ className, white, width, height }) => {
  return (
    <div className={styles.LoaderDiv}>
      <Rings
        height={height ?? "200"}
        width={width ?? "200"}
        color="#3E90F0"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};

export default Loader;


