import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./GraphOverview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
import Users from "../../../components/Users";
import Chart from "./Chart";
import { loadDecryptedValue } from "../../../utils/encryption";
import campaignService from "../../../services/compaign.service";
import { USER_LOCAL } from "../../../constants/user";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardOverview } from "../../../store/slices/SuperuserSlice";
import { getDashboardOverview } from "../../../store/actions/superuser.action";
import { useTranslation } from "react-i18next";


const GraphOverview = ({ className }) => {
  const overViewDate = useSelector(selectDashboardOverview)
  const ddOptions = useSelector(selectDashboardOverview).ddOptions
  const [sorting, setSorting] = useState(ddOptions[0]);
  const [record, setRecord] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [nav, setnav] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation('translation');
  useEffect(()=>{
    //dispatch you logic here
    // dispatch()
  },[sorting])

  const nav = [
    {
      title: t('Referrals'),
      counter: overViewDate.refferals,
      icon: "shopping-bag",
      value:record?.usersWithSameCampaignsPrevious > 0 ?( ((record?.usersWithSameCampaignsCurrent - record?.usersWithSameCampaignsPrevious) / record?.usersWithSameCampaignsPrevious) * 100).toFixed(2) : 100 ,
      color: "#B1E5FC",
      // value: -37.8,
    },
    {
      title: t('total_commission'),
      counter: overViewDate.commision,
      icon: "activity",
      color: "#CABDFF",
      value: 37.8,
    },
  ];

  useEffect(() => {
    dispatch(getDashboardOverview({filter:sorting}))
  }, [sorting]);

  return (
    <Card
      className={cn(styles.card, className)}
      title= {t('overview')}
      classTitle="title-red"
      head={
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sorting}
          setValue={setSorting}
          options={ddOptions}
          small
        />
      }
    >
      {/* <p className={styles.para}> You have 5 new <span className={styles.span}>referrals.</span></p> */}
      <div className={styles.overview}>
        <div className={styles.nav}>
          {nav?.map((x, index) => (
            <Item
              className={cn(styles.item, {
                [styles.active]: index === activeIndex,
              })}
              key={index}
              onActive={() => setActiveIndex(index)}
              item={x}
            />
          ))}
        </div>
        <div className={styles.body}>
          {activeIndex === 0 && <Users />}
          {activeIndex === 1 && <Chart sorting={sorting} />}
        </div>
      </div>
    </Card>
  );
};

export default GraphOverview;
