import useDarkMode from "use-dark-mode";
import styles from "./InviteModal.module.sass";
import { Facebook, Twitter } from "../Icons";
import { useEffect, useState } from "react";
import { Copy } from "../../components/Icons";

import { FacebookShareButton, TwitterShareButton } from "react-share";
import Image from "../Image";
import { RiTwitterXFill } from "react-icons/ri";
import config from "../../config";
import QRCode from "react-qr-code";
import youtube from "../../images/youtube.svg";
import tiktok from "../../images/tiktok.svg";
import tiktokDark from "../../images/tiktokDark.svg";
import instagram from "../../images/instagram.svg";
import linkdedIn from "../../images/linkedIn.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import Notify from "../Notify";
import toast from "react-hot-toast";

export function InviteModal({ id }) {
  const colorMode = useDarkMode();

  console.log("colorMode ", colorMode.value);

  const [link, setLink] = useState(
    `${config.frontEndUrl}/affiliate/${id}`
  );

  const [color, setColor] = useState("light");

  useEffect(() => {
    if (colorMode.value) {
      setColor("dark");
    }
  }, [colorMode]);

  const onCopy = (isCode = false) => {
    // setCopyButtonDisabled(true)
      // setCopied(true);
      setTimeout(() => {
        toast((t) => (
          <Notify iconCheck>
            <div> Link Copied </div>
          </Notify>
        ));
        // setCopyButtonDisabled(false); // Re-enable the copy button after showing the toast
      }, 2000);
  };

  return (
    <div className={styles.inner}>
      {/* <div className={styles.inputDiv}>
        <input placeholder={link} disabled={true} className={styles.input} />
      </div> */}
      <div className={styles.inputDiv}>
        <input placeholder={link} value={'https://dev.lokichat.com/sign-in?campainId=INTYasdasdasdasdsadasdas'} className={styles.input} />
        <CopyToClipboard text={link} 
        onCopy={onCopy}
        >
          <button
            // disabled={copyButtonDisabled}
            className={styles.Copy}
            style={{position:"absolute", background : '#F3F5F7' , width : '30px', top:"50%",left:"95%",transform:"translate(-50%,-50%)",cursor:"pointer"}}
            // ref={copyButtonRef}
            type="button"
          >
            <Copy />
          </button>
        </CopyToClipboard>
      </div>
      
      <figure className={styles.QrImage}>
        {/* <Image
                        src="/images/qrlight.svg"
                        srcDark="/images/qrdark.svg"
                        alt="QR"
                    /> */}
        <QRCode
          size={256}
          // style={{ height: "auto", maxWidth: "50%", width: "100%" }}
          value={link}
          viewBox={`0 0 256 256`}
          className="p-4"
        />
      </figure>
      <h4 className={styles.heading}>Refer People, Earn Rewards!</h4>
      <p className={styles.description}>
        Share your referral link or QR code to onboard others and earn
        commissions effortlessly. Each successful subscription through your link
        adds to your rewards.
      </p>
      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ display: "flex", gap: "16px" }}>
          <a
            href="https://www.linkedin.com/company/lokichat/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid black",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "transparent",
                transition: "background-color 1000ms",
              }}
            >
              <img
                src={linkdedIn}
                alt={"LinkedIn"}
                style={{ height: "24px", width: "24px", objectFit: "contain" }}
              />
            </figure>
          </a>

          <a
            href="https://www.tiktok.com/@_lokichat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid black",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "transparent",
                transition: "background-color 1000ms",
              }}
            >
              <img
                src={color === "light" ? tiktokDark : tiktok}
                alt={"TikTok"}
                style={{ height: "20px", width: "20px", objectFit: "contain" }}
              />
            </figure>
          </a>

          <a
            href="https://www.youtube.com/@_lokichat"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid black",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "transparent",
                transition: "background-color 1000ms",
              }}
            >
              <img
                src={youtube}
                alt={"YouTube"}
                style={{ height: "25px", width: "25px", objectFit: "contain" }}
              />
            </figure>
          </a>

          <a
            href="https://www.instagram.com/_lokichat/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid black",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "transparent",
                transition: "background-color 1000ms",
              }}
            >
              <img
                src={instagram}
                alt={"Instagram"}
                style={{ height: "25px", width: "25px", objectFit: "contain" }}
              />
            </figure>
          </a>
        </div>
      </div>
    </div>
  );
}
