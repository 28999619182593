import React, { useEffect, useState } from "react";
import styles from "./Home.module.sass";
import ProductViews from "./ProductViews";
import GraphOverview from "./GraphOverview";
import Overview from "./Overview";
import Modal from "../../components/Modal";
import CreateCompaginsModal from "../../components/CreateCompaignsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignState,
  getDashboardOverview,
  getTotalData,
} from "../../store/actions/superuser.action";
import { useTranslation } from "react-i18next";

const Home = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  useEffect(() => {
    dispatch(getTotalData({}));
    dispatch(getCampaignState({}));
  }, []);
  return (
    <>
      <div className={styles.headingDiv}>
        <h2 className={styles.heading}>{t("dashboard")}</h2>
        <button className={styles.btn} onClick={() => setVisibleModal(true)}>
          {t("create_campaign")}
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <GraphOverview className={styles.card} />
          <ProductViews className={styles.card} />
        </div>
        <Overview />
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateCompaginsModal setVisibleModal={setVisibleModal} />
      </Modal>
    </>
  );
};

export default Home;
