import { createAsyncThunk } from "@reduxjs/toolkit";
import campaignService from "../../services/compaign.service";


export const getTotalData = createAsyncThunk(
  "superUserStore/getTotalData",
  async (data) => {
    try {
      console.log("inside")
      const response = await campaignService.getTotalData(data);
      console.log(response.data, " get Total Data superUserStore/getTotalData");
      return response.data;
    } catch (error) {
    
           if (error.response) {
        // If the error has a 'response' property
        const { response } = error;
        throw response.data.message || response.statusText;
      } else {
        // If there is no 'response' property in the error object
        throw "An error occurred while fetching data.";
      }
    }
  }
);
export const getDashboardOverview = createAsyncThunk(
  "superUserStore/getDashboardOverview",
  async (data) => {
    try {
      console.log("inside")
      const response = await campaignService.getDashboardOverview(data);
      console.log(response.data, " get Total Data superUserStore/getDashboardOverview");
      return response.data;
    } catch (error) {
    
           if (error.response) {
        // If the error has a 'response' property
        const { response } = error;
        throw response.data.message || response.statusText;
      } else {
        // If there is no 'response' property in the error object
        throw "An error occurred while fetching data.";
      }
    }
  }
);
export const getCampaignState = createAsyncThunk(
  "superUserStore/getCampaignState",
  async (data) => {
    try {
      console.log("inside")
      const response = await campaignService.getCampaignState(data);
      console.log(response.data, " get Total Data superUserStore/getCampaignState");
      return response.data;
    } catch (error) {
    
           if (error.response) {
        // If the error has a 'response' property
        const { response } = error;
        throw response.data.message || response.statusText;
      } else {
        // If there is no 'response' property in the error object
        throw "An error occurred while fetching data.";
      }
    }
  }
);

export const getIncomeData = createAsyncThunk(
  "superUserStore/getIncomeData",
  async (data) => {
    try {
      console.log("inside")
      const response = await campaignService.getIncomeData(data);
      console.log(response.data, " get Total Data superUserStore/getIncomeData");
      return response.data;
    } catch (error) {
    
           if (error.response) {
        // If the error has a 'response' property
        const { response } = error;
        throw response.data.message || response.statusText;
      } else {
        // If there is no 'response' property in the error object
        throw "An error occurred while fetching data.";
      }
    }
  }
);
// export const getAllComapign = createAsyncThunk(
//   "compaignStore/getAllComapign",
//   async (params) => {
//     try {
//       const response = await campaignService.index(params);
//       console.log(response.data.data, "response");
//       return response.data.data;
//     } catch (error) {
//       if (error.response) {
//         // If the error has a 'response' property
//         const { response } = error;
//         throw response.data.message || response.statusText;
//       } else {
//         // If there is no 'response' property in the error object
//         throw "An error occurred while fetching data.";
//       }
//     }
//   }
// );

// export const deleteCampaign = createAsyncThunk(
//   "compaignStore/deleteCompaign",
//   async (id) => {
//     try {
//       const response = await campaignService.destroy(id);
//       return response.data;
//     } catch (error) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// )



// export const updateCompaignById = createAsyncThunk(
//   "compaignStore/updateCompaignById",
//   async (data) => {
//     try {
//       const response = await campaignService.store(data);
//       return response.data; // Assuming your API returns the user data
//     } catch (error) {
//       const { response } = error;
//       throw response.data.message || response.statusText;
//     }
//   }
// );

