import React, { useEffect, useState } from "react";
import styles from "./Compaigns.module.sass";
import Overview from "./Overview";
import Form from "../../components/Form";
import Dropdown from "../../components/Dropdown";
import Table from "./Table";
import Modal from "../../components/Modal";
import CreateCompaginsModal from "../../components/CreateCompaignsModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllComapign } from "../../store/actions/compaign.actions";
import { size } from "lodash";
import { loadDecryptedValue } from "../../utils/encryption";
import { USER_LOCAL } from "../../constants/user";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const Campaigns = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const campaignData = useSelector((state) => state.compaignStore);
  const userData = useSelector((state) => state.authStore);
  const { compaigns } = campaignData;
  const [search, setSearch] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1 });
  const [visibleModal, setVisibleModal] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const intervals = [t("all-time"), t("this-month"), t("this-year")];

  const [sorting, setSorting] = useState(intervals[0]);

  const handleSubmit = (e) => {
    alert();
  };

  useEffect(() => {
    const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);

    dispatch(
      getAllComapign({
        search: search,
        page: paginationInfo.currentPage,
        creator: sessions.userId,
        filter: sorting,
      })
    ).then(() => {
      setApiCall(false);
    });
  }, [search, paginationInfo, sorting]);

  return (
    <>
      <div className={styles.section}>
        <div className={styles.headingDiv}>
          <h2 className={styles.heading}>{t("campaigns")}</h2>
          <button className={styles.btn} onClick={() => setVisibleModal(true)}>
            {t("create_campaign")}
          </button>
        </div>
        <Overview apiCall={apiCall} setApiCall={setApiCall} />
        {/* <div className={styles.btnViewDiv}><button className={styles.btnView}>View All</button></div> */}
        <div className={styles.wrapper}>
          <div className={styles.Compaigns}>
            <div className={styles.formDiv}>
              <div className={styles.fromCompaigns}>
                <h3 className={styles.heading1}> {t("create_campaign")} </h3>
                <Form
                  className={styles.form}
                  value={search}
                  setValue={setSearch}
                  onSubmit={() => handleSubmit()}
                  placeholder={t("search_title")}
                  type="text"
                  name="search"
                  icon="search"
                />
              </div>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
          </div>
          <Table
            data={compaigns?.data}
            loading={compaigns.loading}
            setApiCall={setApiCall}
          />
        </div>
        {compaigns.loading ? (
          <Loader />
        ) : (
          <>
            {compaigns.totalCount > 10 && (
              <div className={styles.loadMoreDiv}>
                {!(
                  Math.ceil(compaigns.totalCount / 10) ==
                  paginationInfo.currentPage
                ) && (
                  <button
                    type="button"
                    className={styles.loadMore}
                    // disabled={compaigns.totalCount == size(compaigns.data)}
                    //implementing page info
                    disabled={
                      Math.ceil(compaigns.totalCount / 10) ==
                      paginationInfo.currentPage
                    }
                    onClick={() => {
                      setPaginationInfo((prev) => ({
                        ...prev,
                        currentPage: prev.currentPage + 1,
                      }));
                    }}
                  >
                    {t("load_moreBtn")}
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <CreateCompaginsModal
          setVisibleModal={setVisibleModal}
          setApiCall={setApiCall}
        />
      </Modal>
    </>
  );
};

export default Campaigns;
