import React from "react";
import styles from "./Customers.module.sass";
import Overview from "./Overview";
import TrafficChannel from "./TrafficChannel";


const Referral = () => {
  return (
    <>
    <Overview className={styles.card} />
    <TrafficChannel className={styles.card} />
    </>
  );
};

export default Referral;
