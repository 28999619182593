import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'; // Note the use of getDefaultMiddleware
import { persistReducer, persistStore, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Correct import for storage
import { encryptTransform } from 'redux-persist-transform-encrypt';
import thunk from 'redux-thunk';
import compaignSlice from './slices/CompaignSlice';
import authSlice from './slices/AuthSlice';
import superUserSlice from './slices/SuperuserSlice';
import notificationsSlice from './slices/NotificationSlice';
const { REDUX_PERSISTOR } = require('../constants/user');
const { migrations } = require('./migrations');

const rootReducer = combineReducers({
  authStore: authSlice,
  compaignStore: compaignSlice,
  superUserStore: superUserSlice,
  notificationStore: notificationsSlice
});

// Configuration for redux-persist
const persistConfig = {
  key: REDUX_PERSISTOR.KEY,
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REDUX_SECRET_KEY || `test-enc-key-${REDUX_PERSISTOR.KEY}`,
    }),
  ],
  version: 1.0,
  migrate: createMigrate(migrations, { debug: true }),
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer and middleware
const middlewares = [thunk]; // Define your middleware(s) here
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export const persistor = persistStore(store);
