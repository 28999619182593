import React, { useEffect, useState } from 'react'
import styles from "./ReferralsList.module.sass"
import { InviteModal } from '../../components/InviteModal'
import Table from './Table'
import Form from '../../components/Form'
import Modal from '../../components/Modal'
import Dropdown from '../../components/Dropdown'
import { loadDecryptedValue } from '../../utils/encryption'
import { USER_LOCAL } from '../../constants/user'
import campaignService from '../../services/compaign.service'
import size from 'lodash'
import { useTranslation } from 'react-i18next'

const ReferralsList = () => {
  const { t } = useTranslation('translation');

  const intervals = [t('all-time'), t('this-month'), t('this-year')];

    const [search, setSearch] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [record, setRecord] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1});
    const [totalCount, setTotalCount] = useState();
    const [sorting, setSorting] = useState(intervals[0]);
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true)
        try {
          const sessions = loadDecryptedValue(USER_LOCAL.LOCAL);
          const response = await campaignService.referralList({creator:sessions.userId,search:search,filter:sorting,page:paginationInfo.currentPage});
          if (paginationInfo.currentPage === 1) {
            setRecord(response?.data?.data.records); 
          } else {
            setRecord((prevRecord) => [...prevRecord, ...response?.data?.data.records]);
          }
          setTotalCount(response?.data?.data.totalRecords)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.error("Error fetching data:", error);
        }
      };
    
      fetchData(); 
    
    }, [search,sorting,paginationInfo]);
  const handleSubmit = (e) => {
    alert();
  };
  return (
    <section className={styles.section}> 
          <div className={styles.formDiv}>
            <div className={styles.fromCompaigns}>
              <h3 className={styles.compaigns}>{t('Referrals')}</h3>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder={t('search_byName')}
              type="text"
              name="search"
              icon="search"
            />
            </div>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={sorting}
            setValue={setSorting}
            options={intervals}
            small
          />
          </div>
       <Table record={record} loading={loading}/>
       {
        totalCount>10&&
        <div className={styles.loadMoreDiv}>
        <button
             type="button"
             className={styles.loadMore}
             disabled={totalCount == size(record)}
             onClick={() => {
               setPaginationInfo((prev) => ({
                 ...prev,
                 currentPage: prev.currentPage + 1,
               }));
             }}
           >
             {t('load_moreBtn')}
           </button>
        </div>
       }
       <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
        <InviteModal />
      </Modal>
    </section>
  )
}

export default ReferralsList