import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from '../../services/auth.service';


export const loginUser = createAsyncThunk(
  "authStore/loginUser", // The action type string
  async (data) => {
    try {
      const response = await authService.login(data);
      return response.data; // Assuming your API returns the user data
    } catch (error) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

export const getUserById = createAsyncThunk(
  "authStore/getUserById", // The action type string
  async (data) => {
    try {
      const response = await authService.getUserById(data);
      console.log(response.data, " ===> User");
      return response.data; // Assuming your API returns the user data
    } catch (error) {
      const { response } = error;
      throw response.data.message || response.statusText;
    }
  }
);

