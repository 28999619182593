import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip";
import Balance from "../../../components/Balance";
import Chart from "./Chart";
import {
  AvailableBalance,
  TotalEarning,
  TotalWithdrawn,
} from "../../../components/Icons";
import { useSelector } from "react-redux";
import {
  selectIncomeData,
  selectTotalData,
} from "../../../store/slices/SuperuserSlice";
import { useTranslation } from "react-i18next";

const Overview = ({ className, commission }) => {
  const { t } = useTranslation("translation");
  const [items, setitems] = useState([]);
  const incomeData = useSelector(selectIncomeData);
  const totalData = useSelector(selectTotalData);

  useEffect(() => {
    setitems([
      {
        title: t("total_earning"),
        // counter: commission.count,
        counter: totalData.totalCommission,
        icon: <TotalEarning />,
        value: 37.8,
        background: "#DAF8FF",
        chartColor: "#83BF6E",
      },
      {
        title: t("available_balance"),
        counter: incomeData.overView.availableBalance.value,
        // counter:  `$${commission?.totalCommission ?? ''}`,
        icon: <AvailableBalance />,
        value: -37.8,
        background: "#F0FAEB",
        chartColor: "#2A85FF",
      },
      {
        title: t("total_withdrawn"),
        counter: incomeData.overView.totalWithDrawn.value,
        icon: <TotalWithdrawn />,
        value: 27.8,
        background: "#FEE1D4",
        chartColor: "#8E59FF",
      },
      {
        title: t("recently_withdrawn"),
        counter: incomeData.overView.recentWithdrawn.value,
        icon: <TotalWithdrawn />,
        value: 17.8,
        background: "#E8DDFB",
        chartColor: "#8E59FF",
      },
    ]);
  }, [incomeData, totalData]);
  return (
    <>
      <Card className={cn(styles.card, className)}>
        <div className={styles.overview}>
          <div className={styles.list}>
            {items?.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x?.background }}
              >
                <div className={styles.icon}>{x?.icon}</div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x?.title}
                      <Tooltip
                        className={styles.tooltip}
                        title={t("small_desc")}
                        icon="info"
                        place="right"
                      />
                    </div>
                    <div className={styles.counter}>{x?.counter}</div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
