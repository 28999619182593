
import { HttpService } from './base.service';
class CampaignService extends HttpService {
    prefix
    constructor() {
        super();
        this.prefix = "compaign";
    }

    store(data) {return this.post(this.prefix + '/store', data);}
    index(data) {return this.get(this.prefix + '/index', data);}
    count(data) {return this.get(this.prefix + '/count', data);}
    indexById(data) {return this.get(this.prefix + '/indexById', data);}
    indexByCode(data) {return this.get(this.prefix + '/indexByCode', data);}
    destroy (id){return this.delete(this.prefix + `/destroy/${id}`)}
    referralList(data) {return this.get(this.prefix + '/referralList', data);}
    newReferralList(data) {return this.get(this.prefix + '/newReferralList', data);}
    referralChart(data) {return this.get(this.prefix + '/referralChart', data);}
    referralGraph(data) {return this.get(this.prefix + '/referralGraph', data);}
    /////////////////////Super User Slice Actions//////////////////////////////
    getTotalData(data) {return this.get(this.prefix + '/getTotalData',data);}
    getDashboardOverview(data) {return this.get(this.prefix + '/getDashboardOverview',data);}
    getCampaignState(data) {return this.get(this.prefix + '/campaignState',data);}
    getIncomeData(data) {return this.get(this.prefix + '/getIncomeData',data);}
    /////////////////////Super User Slice Actions//////////////////////////////
}
const campaignService = new CampaignService();
export default campaignService;


